import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LandingPageComponent} from '@app/modules/template-generator/landing-page';
import {TemplateGeneratorComponent} from './template-generator.component';
import {EditTemplateComponent} from '@app/modules/template-generator/edit-template/edit-template.component';
import {EmailEditorComponent} from '@app/modules/template-generator/email-editor';
import {ShareCampaignComponent} from '@app/modules/template-generator/share-campaign/share-campaign.component';
import {LaunchCampaignComponent} from '@app/modules/template-generator/launch-campaign/launch-campaign.component';
import {SelectTemplateComponent} from '@app/modules/template-generator/select-template/select-template.component';
import {AuthGuard} from '@app/_guards';
import {NonInfluencerGuard} from '@app/_guards/non-influencer.guard';
import {SocialShareEditorComponent} from '@app/modules/template-generator/social-share-editor';
import {PageChangesGuard} from '@app/_guards/page-changes.guard';

const routes: Routes = [
  {
    path: 'projects/:projectId/campaigns/:campaignId',
    component: TemplateGeneratorComponent,
    children: [
      {
        path: 'select',
        pathMatch: 'full',
        component: SelectTemplateComponent
      },
      {
        path: 'share',
        pathMatch: 'full',
        component: ShareCampaignComponent
      },
      {
        path: 'personalize/social-image',
        pathMatch: 'full',
        component: SocialShareEditorComponent
      },
      {
        path: 'landing-page',
        pathMatch: 'full',
        component: LandingPageComponent,
        canDeactivate: [PageChangesGuard]
      },
      {
        path: 'landing-page/edit',
        pathMatch: 'full',
        component: EditTemplateComponent
      },
      {
        path: 'email',
        pathMatch: 'full',
        component: EmailEditorComponent
      },
      {
        path: 'launch',
        pathMatch: 'full',
        component: LaunchCampaignComponent
      }
    ],
    canActivate: [AuthGuard, NonInfluencerGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class TemplateGeneratorRoutingModule {
}
