import {Component, OnInit, Input, EventEmitter, Output, NgModule, OnDestroy} from '@angular/core';
import {LANDING_PAGE_TEMPLATE_TYPE_ID} from '@app/consts';
import {FontPickerDirective} from '@app/modules/fontPicker/fontPicker.directive';
import {select, Store} from '@ngrx/store';
import {
  getTemplateSectionError,
  getTemplateSections, getTemplateSectionTypes,
  ITemplateSectionState,
  TemplateSectionListAll, TemplateSectionTypeList
} from '@app/stores';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {SocketService} from '@app/services/socket.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-template-sidebar',
  templateUrl: './select-template-sidebar.component.html',
  styleUrls: ['./select-template-sidebar.component.scss']
})
export class SelectTemplateSidebarComponent implements OnInit, OnDestroy {
  @Output() public OnTemplateSelect: EventEmitter<any> = new EventEmitter();
  @Input() onCustomizationChange;
  @Input() form;
  @NgModule({
    imports: [FontPickerDirective]
  })

  public currentMode;
  public templates = [];
  public currentToolBar = 'bodySettings';
  public modes = {
    RAW_TEMPLATE_LISTING: 'RAW_TEMPLATE_LISTING',
    TOOL_BAR: 'TOOL_BAR',
  };
  public rawTemplatesTypes = [];
  unsubscriber = new Subject();
  subscription: any;

  constructor(
    private templateSectionStore: Store<ITemplateSectionState>,
    private toastr: ToastrService,
    private socketService: SocketService
  ) {
    this.subscribeStore();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            const temp = _.cloneDeep(this.templates);
            temp.map(element => {
              if (element.id === message.data.requesterId && message.data?.requesterType === 'section') {
                element.thumbnail = message.data.result.thumbnail;
              }
              return element;
            });
            this.templates = [...temp];
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  subscribeStore() {
    this.templateSectionStore.pipe(select(getTemplateSections))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSections => {
        if (templateSections && templateSections.length > 0) {
          this.templates = templateSections;
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSectionTypes => {
        if (templateSectionTypes && templateSectionTypes.length > 0) {
          this.rawTemplatesTypes = templateSectionTypes;
          this.fetchRawTemplatesById(this.rawTemplatesTypes[0].id);
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
  }

  ngOnInit() {
    this.currentMode = this.modes.RAW_TEMPLATE_LISTING;
    this.fetchRawTemplateTypes();
  }

  onListIconClick() {
    this.currentMode = this.modes.RAW_TEMPLATE_LISTING;
  }

  onBrushIconClick() {
    this.currentMode = this.modes.TOOL_BAR;
  }

  onRawTemplateTypeChange(event) {
    const {options} = event.target;
    const {selectedIndex} = options;
    const id = options[selectedIndex].getAttribute('value');

    this.fetchRawTemplatesById(id);
  }

  onRawTemplateSelect(item): void {
    this.OnTemplateSelect.emit({item});
  }

  onStylesCustomizationChange(value, field) {
    this.onCustomizationChange('styles', {field, value});
  }

  fetchRawTemplatesById(id) {
    this.templateSectionStore.dispatch(TemplateSectionListAll({
      params: {
        template_section_type_id: id,
        template_type_id: LANDING_PAGE_TEMPLATE_TYPE_ID
      }
    }));
  }

  fetchRawTemplateTypes() {
    this.templateSectionStore.dispatch(TemplateSectionTypeList({params: {}}));
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
    this.subscription.unsubscribe();
  }
}
