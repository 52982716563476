import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Constants } from "@app/consts";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import moment from 'moment-timezone';
import {ToastrService} from "ngx-toastr";
//
// @TODO: add this in a separate file to reuse
interface ScheduleDate {
  schedule_date?: string;
  schedule_time?: string;
  timezone?: any;
}

@Component({
  selector: "app-launch-schedule-modal",
  templateUrl: "./launch-schedule-modal.component.html",
  styleUrls: ["./launch-schedule-modal.component.scss"],
  providers: [Constants],
})
export class LaunchScheduleModalComponent implements OnInit {
  public form: UntypedFormGroup;

  public min: Date = new Date();
  public max: Date = this.min;

  public schedule: ScheduleDate = {};
  public launchType: string = '';
  public timezones: Object[] = [];

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    public modal: NgbActiveModal,
    public constants: Constants,
    private toastr: ToastrService,
  ) {
    //
    // Set timezone list
    this.timezones = constants.timezone;
  }

  ngOnInit() {
    //
    // Create form
    this.createForm();
  }

  timezoneSelect(event: any) {
    this.form.patchValue({timezone: event.value});
  }

  createForm() {
    let schedule_date;
    let schedule_time;
    if (this.schedule?.schedule_date) schedule_date = moment(this.schedule?.schedule_date).format('MM/DD/YYYY');
    if (this.schedule?.schedule_time) schedule_time = moment(this.schedule?.schedule_time, 'HH:mm:ss').format('h:mm A');
    this.form = this.formBuilder.group({
      schedule_date: [schedule_date, Validators.required],
      schedule_time: [schedule_time, Validators.required],
      timezone: [this.schedule?.timezone],
    });
    this.form.patchValue({timezone: this.schedule?.timezone});
  }

  close() {
    this.modal.close();
  }

  save() {
    //
    // Validate form
    // @TODO: show some kind of feedback to the user
    if (this.form.invalid) {
      return;
    }
    const selectedTz = this.form.get('timezone').value;
    if (!selectedTz) {
      return;
    }

    this.form.patchValue({timezone: selectedTz});
    const sendData = {
      schedule_date: moment(this.form.get('schedule_date').value).format('YYYY-MM-DD'),
      schedule_time: moment(this.form.get('schedule_time').value, 'h:mm A').format('HH:mm:ss'),
      timezone: this.form.get('timezone').value?.nameValue ? this.form.get('timezone').value.nameValue : selectedTz
    };
    const selectedDateTime: any = moment.tz(sendData.schedule_date + ' ' + sendData.schedule_time, sendData.timezone);
    const diff = selectedDateTime.utc().diff(moment().utc());
    /** If time not passed */
    if (diff <= 0) {
      // throw error
      this.toastr.error(`Current selected date and time is older than the curren time`);
    } else {
      //
      // Close modal with current schedule object
      this.modal.close(sendData);
    }
  }

  getTimezoneValue(event) {
    this.form.patchValue({timezone: event});
  }
}
