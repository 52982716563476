<div class='p-0 bg-white container-container'>
  <div class='mb-3 bg-light w-100 d-flex justify-content-center'>
    <div class='col-md-6 d-flex justify-content-center'>
      <button (click)='onListIconClick()' class='btn mx-auto' style='outline: none; box-shadow: none;'>
        <i class='fa fa-list {{ currentMode === modes.RAW_TEMPLATE_LISTING ? "active" : "" }}' aria-hidden='true'></i>
      </button>
    </div>
    <div class='col-md-6 d-flex justify-content-center'>
      <button (click)='onBrushIconClick()' class='btn' style='outline: none; box-shadow: none;'>
        <i class='fa fa-paint-brush {{ currentMode === modes.TOOL_BAR ? "active" : "" }}' aria-hidden='true'></i>
      </button>
    </div>
  </div>

  <div *ngIf='currentMode === modes.RAW_TEMPLATE_LISTING' class='pl-3 pr-3 w-100'>
    <div class='mb-3'>
      <select class='custom-select w-100' (change)='onRawTemplateTypeChange($event)'>
        <option *ngFor='let item of rawTemplatesTypes' value='{{item.id}}'
                style="text-transform:capitalize;">{{item.name}}</option>
      </select>
    </div>
    <div class='container' cdkDropList [cdkDropListData]="templates">
      <div *ngFor='let item of templates' class='card mb-3 block' (click)='onRawTemplateSelect(item)'>
        <a><img src="{{item['thumbnail']?item['thumbnail']:'/assets/images/block_placeholder.png'}}" class='card-img'/></a>
      </div>
    </div>
  </div>

  <div *ngIf='currentMode === modes.TOOL_BAR' class="w-100 h-100">
    <div class='mb-3 pl-3'>
      <small>PAGE SETTINGS</small>
    </div>
    <div class='accordion' id='bodyPropertiesAccordion'>
      <div class='card card-box'>
        <div class='card-header p-0 pl-3' id='bodyProperties'>
          <small>Body Properties</small>
          <button class='btn btn-link ml-auto' type='button' data-toggle='collapse' data-target='#bodySettingsContainer'
                  aria-expanded='true' aria-controls='collapseOne'
                  (click)="currentToolBar = currentToolBar ? undefined : 'bodyProperties'">
            <i class='fa fa-angle-{{ currentToolBar === "bodyProperties" ? "down" : "right" }}'></i>
          </button>
        </div>

        <div class='pt-3 collapse show' id='bodySettingsContainer' aria-labelledby='bodyProperties'
             data-parent='#bodyPropertiesAccordion'>
          <div class='form-group'>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for="max_width"><small>Width</small></label>
                <div class='input-group input-group-sm'>
                  <input id="max_width" style="height: 36px;" class='form-control' type='number' data-field='max_width'
                         data-unit="px"
                         (change)="onStylesCustomizationChange($event.target.value, 'max_width')"
                         value="{{form.max_width}}"/>
                  <div class='input-group-append'>
                    <span class='input-group-text'>px</span>
                  </div>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for="margin_top"><small>Margin Top</small></label>
                <div class='input-group input-group-sm'>
                  <input id="margin_top" style="height: 36px;" class='form-control' type='number'
                         data-field='margin_top' data-unit="px"
                         (change)="onStylesCustomizationChange($event.target.value, 'margin_top')"
                         value="{{form.margin_top}}"/>
                  <div class='input-group-append'>
                    <span class='input-group-text'>px</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='accordion' id='colorSettingsAccordion'>
      <div class='card card-box'>
        <div class='card-header p-0 pl-3' id='colorSettings'>
          <small>Colors</small>
          <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                  data-target='#colorSettingsContainer' aria-expanded='true' aria-controls='collapseOne'
                  (click)="currentToolBar = currentToolBar ? undefined : 'colorSettings'">
            <i class='fa fa-angle-{{ currentToolBar === "colorSettings" ? "down" : "right" }}'></i>
          </button>
        </div>

        <div class='pt-3 collapse show' id='colorSettingsContainer' aria-labelledby='colorSettings'
             data-parent='#colorSettingsAccordion'>
          <div class='form-group'>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='background_color'><small>Background</small></label>
                <div class='input-group input-group-sm'>
                  <input id="background_color" class='form-control' [style.background]="form.background_color"
                         [value]="form.background_color"
                         [(colorPicker)]="form.backgroundColor" [cpPosition]="'right'" [cpOKButton]="true"
                         [cpCancelButton]="true" [cpOKButtonClass]="'btn btn-first ml-2'"
                         [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'background_color')"/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='font_color'><small>Text Font Color</small></label>
                <div class='input-group input-group-sm'>
                  <input id="font_color" class='form-control' [style.background]="form.font_color"
                         [value]="form.font_color" [(colorPicker)]="form.font_color"
                         [cpPosition]="'top'" [cpOKButton]="true" [cpCancelButton]="true"
                         [cpOKButtonClass]="'btn btn-first ml-2'" [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'font_color')"/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='header_font_color'><small>Headings Font Color</small></label>
                <div class='input-group input-group-sm'>
                  <input id="header_font_color" class='form-control' [style.background]="form.header_font_color"
                         [value]="form.header_font_color"
                         [(colorPicker)]="form.header_font_color" [cpPosition]="'right'" [cpOKButton]="true"
                         [cpCancelButton]="true" [cpOKButtonClass]="'btn btn-first ml-2'"
                         [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'header_font_color')"/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='link_color'><small>Link Color</small></label>
                <div class='input-group input-group-sm'>
                  <input id="link_color" class='form-control' [style.background]="form.link_color"
                         [value]="form.link_color" [(colorPicker)]="form.link_color"
                         [cpPosition]="'top'" [cpOKButton]="true" [cpCancelButton]="true"
                         [cpOKButtonClass]="'btn btn-first ml-2'" [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'link_color')"/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='button_color'><small>Button Color</small></label>
                <div class='input-group input-group-sm'>
                  <input id="button_color" class='form-control' [style.background]="form.button_color"
                         [value]="form.button_color" [(colorPicker)]="form.button_color"
                         [cpPosition]="'top'" [cpOKButton]="true" [cpCancelButton]="true"
                         [cpOKButtonClass]="'btn btn-first ml-2'" [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'button_color')"/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='button_font_color'><small>Button Text Color</small></label>
                <div class='input-group input-group-sm'>
                  <input id="button_font_color" class='form-control' [style.background]="form.button_font_color"
                         [value]="form.button_font_color" [(colorPicker)]="form.button_font_color"
                         [cpPosition]="'top'" [cpOKButton]="true" [cpCancelButton]="true"
                         [cpOKButtonClass]="'btn btn-first ml-2'" [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'button_font_color')"/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for="button_border_color"><small>Button Border Color</small></label>
                <div class='input-group input-group-sm'>
                  <input id="button_border_color" class='form-control'
                         [style.background]="form.button_border_color"
                         [value]="form.button_border_color" [(colorPicker)]="form.button_border_color"
                         [cpPosition]="'top'" [cpOKButton]="true" [cpCancelButton]="true"
                         [cpOKButtonClass]="'btn btn-first ml-2'" [cpCancelButtonClass]="'btn btn-first'"
                         (colorPickerSelect)="onStylesCustomizationChange($event, 'button_border_color')"/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class='accordion' id='fontStylesAccordion'>
      <div class='card card-box'>
        <div class='card-header p-0 pl-3' id='fontStyles'>
          <small>Fonts</small>
          <button class='btn btn-link ml-auto' type='button' data-toggle='collapse' data-target='#fontStylesContainer'
                  aria-expanded='true' aria-controls='collapseOne'
                  (click)="currentToolBar = currentToolBar ? undefined : 'fontStyles'">
            <i class='fa fa-angle-{{ currentToolBar === "fontStyles" ? "down" : "right" }}'></i>
          </button>
        </div>

        <div class='pt-3 collapse show' id='fontStylesContainer' aria-labelledby='fontStyles'
             data-parent='#fontStylesAccordion'>
          <div class='form-group'>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='font_family'><small>Text Font Family</small></label>
                <div class='input-group input-group-sm'>
                  <input value="{{form.font_family}}" id="font_family" appFontPicker class='form-control' type='text'
                         (change)='onStylesCustomizationChange($event.target.value, "font_family")'/>
                </div>
              </div>
            </div>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='header_font_family'><small>Headings Font Family</small></label>
                <div class='input-group input-group-sm'>
                  <input value="{{form.header_font_family}}" id="header_font_family" appFontPicker class='form-control'
                         type='text'
                         (change)='onStylesCustomizationChange($event.target.value, "header_font_family")'/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class='accordion' id='customCSSAccordion'>
      <div class='card card-box'>
        <div class='card-header p-0 pl-3' id='customCssSettings'>
          <small>Custom Css</small>
          <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                  data-target='#customCSSContainer' aria-expanded='true' aria-controls='collapseOne'
                  (click)="currentToolBar = currentToolBar ? undefined : 'customCssSettings'">
            <i class='fa fa-angle-{{ currentToolBar === "customCssSettings" ? "down" : "right" }}'></i>
          </button>
        </div>

        <div class='pt-3 collapse show' id='customCSSContainer'
             data-parent='#customCSSAccordion'>
          <div class='form-group'>
            <div class='row'>
              <div class='col-md-9 mb-3 mx-auto'>
                <label for='background_color'><small>Custom Css</small></label>
                <div class='input-group input-group-sm'>
                  <textarea class="form-control custom-css" [value]="form.custom_css || ''"
                            (keyup)="onStylesCustomizationChange($event.target.value, 'custom_css')">
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
