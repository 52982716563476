import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LANDING_PAGE_TEMPLATE_TYPE_ID} from '@app/consts';
import * as routes from '@app/routes';
import {Observable} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TemplateEditorComponent} from '@app/_components/template-editor/template-editor.component';
import {select, Store} from '@ngrx/store';
import {
  CampaignUpdate, getInfluencerError, getInfluencersTypes, getLoggedInUser, getTemplateError,
  getTemplates,
  getTemplateSectionError,
  getTemplateSections,
  getTemplateSectionTypes,
  getTemplateTypes, IAuthenticationState,
  ICampaignState, IInfluencersState, InfluencersTypeList,
  ITemplateSectionState,
  ITemplateState, ResetInfluencerState, ResetTemplateSectionState,
  ResetTemplateState,
  TemplateGetTypes,
  TemplateListAll,
  TemplateSectionListAll,
  TemplateSectionTypeList
} from '@app/stores';
import {ToastrService} from 'ngx-toastr';
import {SocketService} from '@app/services/socket.service';
import * as _ from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TemplateRevisionsComponent} from '@app/_components/template-revisions/template-revisions.component';
import Swal from 'sweetalert2';
import { InfluencerType, User } from '@app/modules/shared/models';
import {CanComponentDeactivate} from '@app/_guards/page-changes.guard';

@Component({
  selector: 'app-landing',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('templateEditor', {static: false}) templateEditor: TemplateEditorComponent;
  public step;
  public campaign;
  public variables;
  public projectId;
  public viewportSize = 'desktop';
  public template: any;
  public editHtml = false;
  public templates = [];
  public rawTemplatesTypes = [];
  public templateForm: UntypedFormGroup;
  public stylesForm: UntypedFormGroup;
  templateSaveType = '';
  public templateTypeId = LANDING_PAGE_TEMPLATE_TYPE_ID;
  subscription: any;
  saveAsTemplateName = '';
  saveAsTemplateNameSubmit = false;

  public selectedSection;
  public isTemplateLoaded = false;
  public sections: Array<String> = [];
  private clickEvents = {
    saveClick: false,
    nextClick: false,
    previewClick: false
  };
  private templateSubscription;
  loader$: Observable<boolean>;
  unsubscriber = new Subject();
  public defaultProperties = {
    max_width: '',
    margin_top: '0',
    block_margin: '',
    block_padding: '',
    block_background: '#ffffff',
    background_color: '#ffffff',
    font_color: '#000000',
    header_font_color: '#000000',
    link_color: '#000000',
    button_color: '#329ef7',
    button_font_color: '#ffffff',
    button_border_color: '#329ef7',
    button_hover_color: '',
    font_family: 'Arial',
    header_font_family: 'Arial',
    custom_css: '',
  };

  landingPageEnabled = true;
  templateReset = false;
  isAdmin = false;
  currentUser: User;
  influencerTypes: InfluencerType[] = [];
  newDummyInfluencerType: any = {};
  public saveTemplateForm: UntypedFormGroup;
  influencerTypeId: any;
  hasUnsavedChanges: boolean = false;
  public newEditor = {
    show: false,
    active: false
  };
  public templateData = {
    body: null,
    bodyJson: null,
    visualEditorId: null,
    campaignId: null,
    influencerTypeId: null,
    templateId: null,
    templateTypeId: null,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private loaderService: LoaderService,
    private templateGeneratorService: TemplateGeneratorService,
    private templateSectionStore: Store<ITemplateSectionState>,
    private templateStore: Store<ITemplateState>,
    private campaignStore: Store<ICampaignState>,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private socketService: SocketService,
    private _modalService: NgbModal,
    private authenticationStore: Store<IAuthenticationState>,
    private influencersStore: Store<IInfluencersState>,
  ) {
    // window.onbeforeunload = function(event) {
    //   event.preventDefault();
    //   event.returnValue = true;
    // };
    this.templateStore.dispatch(ResetTemplateState({params: {previewUrl: null, error: '', success: ''}}));
    this.templateStore.dispatch(ResetTemplateSectionState({params: {error: '', templateSectionTypes: [], templateSections: []}}));
    this.templateGeneratorService.subscribeStore();
    this.subscribeStore();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            const temp = _.cloneDeep(this.templates);
            temp.map(element => {
              if (element.id === message.data.requesterId && message.data?.requesterType === 'section') {
                element.thumbnail = message.data.result.thumbnail;
              }
              return element;
            });
            this.templates = [...temp];
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return !this.hasUnsavedChanges;
  }

  beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    if (!this.hasUnsavedChanges) {
      event.preventDefault();
      event.returnValue = ''; // Show a warning dialog in most browsers
    }
  }

  subscribeStore() {
    this.authenticationStore.pipe(select(getLoggedInUser))
        .pipe(takeUntil(this.unsubscriber))
        .subscribe(user => {
              if (user) {
                this.currentUser = user;
                this.isAdmin = this.currentUser.role_user[0].role_id === 1;
              }
            }
        );
    this.templateSectionSubscribeStore();
    this.influencerStoreSubscribe();
  }

  influencerStoreSubscribe() {
    this.influencersStore.pipe(select(getInfluencerError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });

    this.influencersStore.pipe(select(getInfluencersTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(influencerTypes => {
        if (influencerTypes && influencerTypes.length > 0) {
          this.newDummyInfluencerType.id = null;
          this.newDummyInfluencerType.name = 'All';
          this.influencerTypes = [
            this.newDummyInfluencerType,
            ...influencerTypes
          ];
        }
      });
  }

  templateSectionSubscribeStore() {
    this.templateSectionStore.dispatch(TemplateSectionTypeList({params: {}}));
    this.templateSectionStore.pipe(select(getTemplateSections))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSections => {
        if (templateSections && templateSections.length > 0) {
          this.templates = templateSections;
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionTypes))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(templateSectionTypes => {
        if (templateSectionTypes && templateSectionTypes.length > 0) {
          this.rawTemplatesTypes = templateSectionTypes.filter(x => x.name !== 'social images');
          this.fetchRawTemplatesById(this.rawTemplatesTypes[0].id);
        }
      });
    this.templateSectionStore.pipe(select(getTemplateSectionError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
  }

  fetchRawTemplatesById(id) {
    // const options = this.isAdmin ? {includeOnlyVisible: true} : {};
    this.templateSectionStore.dispatch(TemplateSectionListAll({
      params: {
        // options: JSON.stringify(options),
        template_section_type_id: id,
        template_type_id: LANDING_PAGE_TEMPLATE_TYPE_ID
      }
    }));
  }

  ngOnInit() {
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
    this.loader$ = this.loaderService.loader$;
    this.templateGeneratorService.clearTemplate(LANDING_PAGE_TEMPLATE_TYPE_ID);
    this.saveTemplateForm = this.formBuilder.group({
      saveAsTemplateName: new UntypedFormControl('', [Validators.required]),
      influencer_type_id: new UntypedFormControl(null),
    });
    this.initializeForms();
    this.activatedRoute.params.subscribe(() => {
        if (this.campaign) {
          this.templateGeneratorService.loadClientTemplate({templateTypeId: this.templateTypeId});
        }
        this.subscribeToTemplateChanges();
        if (this.campaign) {
          this.setStep();
        }
      }
    );
    this.influencersStore.dispatch(InfluencersTypeList());
    this.activatedRoute.queryParams.subscribe(async (params) => {
      const {sourceTemplateId} = params;
      this.subscribeToCampaign(sourceTemplateId || 0);
    });
  }

  subscribeToTemplateChanges() {
    if (this.templateSubscription) {
      this.templateSubscription.unsubscribe();
    }
    this.templateSubscription = this.templateGeneratorService.onTemplateChanged[this.templateTypeId]
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((template) => {
        if (template) {
          if (!this.clickEvents.nextClick) {
            this.replaceTemplate(template);
            console.log('this.template', this.template);
            this.templateData = {
              visualEditorId: this.template.visual_editor_id,
              body: this.template.body,
              bodyJson: this.template.body_json,
              campaignId: this.template.campaign_id,
              influencerTypeId: this.template.influencer_type_id,
              templateId: this.template.id,
              templateTypeId: this.template.template_type_id
            };
          }
        }
      });
    this.templateGeneratorService.onCampaignUpdated
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((template) => {
        if (template) {
          if (this.clickEvents.saveClick) {
            this.router.navigateByUrl('/' + routes.campaigns({projectId: this.campaign.project_id}));
          }
          if (this.clickEvents.nextClick) {
            this.router.navigateByUrl(
              routes.campaignShare({
                projectId: this.campaign.project_id,
                campaignId: this.campaign.id
              })
            );
          }

          if (this.clickEvents.previewClick) {
            this.templateGeneratorService.preview(this.template.id);
          }
          this.clickEvents = {
            saveClick: false,
            nextClick: false,
            previewClick: false
          };
        }
      });
  }

  onPageSizeSelect(event) {
    this.viewportSize = event;
  }

  onEditHtml() {
    this.templateEditor.onEditHtml();
  }

  subscribeToCampaign(sourceTemplateId: number) {
    this.templateGeneratorService.onCampaignLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(camp => {
        if (camp) {
          this.campaign = camp;
          this.landingPageEnabled = this.campaign.landing_page_status === 'active';
          const segment = this.campaign.segments[0];
          this.templateGeneratorService.loadVariables({influencerTypeId: segment.influencer_type_id, campaignId: this.campaign.id},
            (vars) => {
              this.variables = vars;
            });
          if (this.campaign.segments.length > 0) {
            //
            // Do not load template automatically when there is blank queryParam
            if (!this.activatedRoute.snapshot.queryParams?.blank) {
              this.templateGeneratorService.loadClientTemplate(
                {
                  templateTypeId: LANDING_PAGE_TEMPLATE_TYPE_ID,
                  sourceTemplateId: sourceTemplateId !== 0 ? sourceTemplateId : '',
                },
                {createIfNotExist: false, forceTouch: false}
              );
            }

            this.setStep();
          } else {
            this.router.navigate(
              ['/project/' + this.campaign.project_id + '/add-influencer'],
              {queryParams: {campId: this.campaign.id}}
            );
          }
        }
      });
  }

  getVariables() {
    if (this.campaign) {
      return this.variables;
    }
  }

  replaceTemplate(template) {
    if (template) {
      let t: any = {};
      if (this.templateReset) {
        this.templateReset = false;
        this.template = {...this.template};
        this.template.body = template.body;
        this.template.style = template.style;
        this.template.properties = template.properties;
        t = this.template || {};
      } else {
        this.template = template;
        t = template || {};
      }

      if (template?.visual_editor_id) {
        this.newEditor.active = true;
      } else {
        this.newEditor.active = false;
      }

      this.templateForm.setValue({
        name: t.name || '',
        meta_tags: t.meta || ''
      });
      const formFields = {};
      let properties = t.properties;
      if (properties) {
        while (typeof properties === 'string') {
          properties = JSON.parse(properties);
        }
        properties = {...properties};
        Object.entries(this.stylesForm.controls).forEach(([key, val]) => {
          this.setDefaultProperties(key, properties);
          formFields[key] = properties[key];
        });
      }
      this.stylesForm.patchValue(formFields);
    }
  }

  setDefaultProperties(key, properties) {
    if (!properties[key]) {
      properties[key] = this.defaultProperties[key];
    }
  }

  setStep() {
    this.step = {
      title: 'Landing Page',
      params: {
        projectId: this.campaign.project_id,
        campaignId: this.campaign.id,
        campaignName: this.campaign.name
      }
    };
  }

  initializeForms() {
    this.templateForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      meta_tags: new UntypedFormControl('', [Validators.required])
    });
    this.stylesForm = this.formBuilder.group({
      max_width: new UntypedFormControl(''),
      margin_top: new UntypedFormControl('0'),
      background_color: new UntypedFormControl(''),
      block_margin: new UntypedFormControl(''),
      block_padding: new UntypedFormControl(''),
      block_background: new UntypedFormControl(''),
      font_color: new UntypedFormControl(''),
      header_font_color: new UntypedFormControl(''),
      link_color: new UntypedFormControl(''),
      button_color: new UntypedFormControl(''),
      button_font_color: new UntypedFormControl(''),
      button_border_color: new UntypedFormControl(''),
      button_hover_color: new UntypedFormControl(''),
      font_family: new UntypedFormControl(''),
      header1_font_family: new UntypedFormControl(''),
      header2_font_family: new UntypedFormControl(''),
      header3_font_family: new UntypedFormControl(''),
      header4_font_family: new UntypedFormControl(''),
      header5_font_family: new UntypedFormControl(''),
      header6_font_family: new UntypedFormControl(''),
      custom_css: new UntypedFormControl(''),
    });
  }

  async buildTemplateForSave(htmlParts?: any) {
    let template: any = {};
    if (this.template) {
      template = {...this.template};
    }
    template.properties = {};
    const {name} = this.templateForm.value;
    if (!this.newEditor.active) {
      if (!htmlParts) {
        htmlParts = await this.templateEditor.buildTemplateForSave();
      }
      const {value} = this.stylesForm;
      Object.entries(value).forEach(([key, val]) => {
        template.properties[key] = val;
      });
      let htmlBody: any = htmlParts.body;
      htmlBody = htmlBody.replaceAll(' fp-active', '');
      htmlBody = htmlBody.replaceAll('fp-active', '');
      template.body = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
      template.body_json = null;
      template.style = htmlParts.style;
    } else {
      template.visual_editor_id = this.templateData.visualEditorId;
      template.body = this.templateData.body;
      template.body_json = this.templateData.bodyJson;
      template.style = null;
    }
    template.name = name;
    template.template_type_id = this.templateData.templateTypeId;
    template.influencer_type_id = this.templateData.influencerTypeId;
    template.client_id = this.campaign.client_id;
    template.project_id = this.campaign.project_id;
    template.campaign_id = this.templateData.campaignId;
    delete template.content_hash;
    return template;
  }

  // async buildTemplateForSave(htmlParts?: any) {
  //   if (!htmlParts) {
  //     htmlParts = await this.templateEditor.buildTemplateForSave();
  //   }
  //   const {name} = this.templateForm.value;
  //   const {value} = this.stylesForm;
  //   let template: any = {};
  //   if (this.template) {
  //     template = {...this.template};
  //   }
  //
  //   let htmlBody: any = htmlParts.body;
  //   htmlBody = htmlBody.replaceAll(' fp-active', '');
  //   htmlBody = htmlBody.replaceAll('fp-active', '');
  //   template.body = htmlBody.replaceAll(/data-id="[^"]*"/g, '');
  //   template.style = htmlParts.style;
  //   template.name = name;
  //   template.properties = {};
  //   Object.entries(value).forEach(([key, val]) => {
  //     template.properties[key] = val;
  //   });
  //   delete template.content_hash;
  //   return template;
  // }

  getForm() {
    return {...this.stylesForm.value};
  }

  onStylesCustomizationChange(params) {
    this.stylesForm.patchValue({...params});
  }

  onBackButtonClick(forceSelect = false) {
    if (forceSelect) {
      this.router.navigateByUrl(routes.campaignSelectTemplate({projectId: this.campaign.project_id, campaignId: this.campaign.id},
        {select: true}));
    } else {
      this.router.navigateByUrl(routes.addInfluencers({projectId: this.campaign.project_id}, {campId: this.campaign.id}));
    }
  }

  onSaveButtonClick(saveAndExit = true) {
    this.clickEvents = {
      ...this.clickEvents,
      saveClick: saveAndExit
    };
    this.hasUnsavedChanges = true;
    this.saveClientTemplate();
  }

  async saveAsNewTemplate(modal: any) {
    this.saveAsTemplateNameSubmit = true;
    this.saveAsTemplateName = this.saveTemplateForm.get('saveAsTemplateName').value;
    if (this.saveTemplateForm.get('influencer_type_id').value === 'null') {
      this.saveTemplateForm.patchValue({'influencer_type_id': null});
    }
    this.influencerTypeId = this.saveTemplateForm.get('influencer_type_id').value;
    if (this.saveAsTemplateName) {
      const template = await this.buildTemplateForSave();
      modal.close();
      let extraParams;
      if (this.templateSaveType === 'client') {
        extraParams = {
          name: this.saveAsTemplateName,
          client_id: this.campaign.client_id,
          project_id: null,
          campaign_id: null,
          influencer_type_id: this.influencerTypeId
        };
      } else {
        extraParams = {
          name: this.saveAsTemplateName,
          client_id: null,
          project_id: null,
          campaign_id: null,
          influencer_type_id: this.influencerTypeId
        };
      }

      this.templateGeneratorService.touchTemplate({
        templateTypeId: LANDING_PAGE_TEMPLATE_TYPE_ID,
        sourceTemplateId: template.id,
        extraParams
      });
    }
  }

  async saveAsNewTemplateModel(template, type) {
    this.saveTemplateForm.patchValue({'influencer_type_id': this.campaign.segments[0].influencer_type_id});
    this.templateSaveType = type;
    const modelRef = this._modalService.open(template, {
      centered: false,
      size: 'sm',
      keyboard: true
    });
    modelRef.result.then((result) => {
    }, (reason) => {
    });
  }

  async onNextButtonClick() {
    // Validate template, body should not be empty
    const template: any = await this.templateEditor.buildTemplateForSave();
    if (!template?.body) {
      return this.toastr.error('Template is empty, add some content and try again', 'Error');
    }

    this.clickEvents = {
      ...this.clickEvents,
      nextClick: true
    };
    this.hasUnsavedChanges = true;
    this.saveClientTemplate(template);
  }

  async saveClientTemplate(template?: any) {
    const clientTemplate = await this.buildTemplateForSave(template);
    // if (!clientTemplate.body.includes('#InfluencerRegistrationUrl#')) {
    if (!clientTemplate.body.includes('#InfluencerCallToActionUrl#') && !clientTemplate.body.includes('#InfluencerCTA#') &&
        !clientTemplate.body.includes('#ProjectCallToActionUrl#') && !clientTemplate.body.includes('#ProjectCTA#')) {
      Swal.fire({
        // title: '#InfluencerRegistrationUrl# is not used on the page. This would cause problem with report as well as referral tracking system. Are you sure you want to continue?',
        title: '#InfluencerCallToActionUrl# or #InfluencerCTA# or #ProjectCallToActionUrl# or #ProjectCTA# is not used on the page. This would cause problem with report as well as referral tracking system. Are you sure you want to continue?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Continue',
        cancelButtonText: 'Close',
        width: '70%'
      }).then(result => {
        if (result.value) {
          this.saveTemplateFinal(clientTemplate);
        }
      });
    } else {
      this.saveTemplateFinal(clientTemplate);
    }
  }

  saveTemplateFinal(clientTemplate) {
    this.templateGeneratorService.saveClientTemplate({
      id: this.template ? this.template.id : null,
      templateTypeId: this.templateTypeId,
      campaignId: this.campaign.id,
      clientTemplate,
      visualEditorId: null,
    });
    // const campaign = {
    //   landing_page_status: this.landingPageEnabled ? 'active' : 'skipped'
    // };
    const campaign = {
      landing_page_status: 'active'
    };

    if (this.activatedRoute.snapshot.queryParams?.blank) {
      const params = { ...this.activatedRoute.snapshot.queryParams };
      delete params.blank;
      this.location.replaceState(routes.campaignTemplates({
        projectId: this.projectId,
        campaignId: this.campaign.id,
      }, params));
    }

    this.campaignStore.dispatch(CampaignUpdate({campaign, campaignId: this.campaign.id}));
  }

  onPreviewButtonClick() {
    this.clickEvents = {
      ...this.clickEvents,
      previewClick: true
    };
    this.hasUnsavedChanges = true;
    this.saveClientTemplate();
  }

  onSendTestEmailButtonClick() {
    const email = prompt('Please enter email to send the test email to.', 'email');
    if (email) {
      this.templateGeneratorService.sendTestEmail(email);
    }
  }

  templateLoaded(event) {
    this.isTemplateLoaded = event;
    this.templateEditor.enableEditor();
  }

  showTemplateRevisions() {
    const modelRef: any = this._modalService.open(TemplateRevisionsComponent, {centered: true, keyboard: true, size: 'xl'});
    modelRef.componentInstance.templateId = this.template?.id;
    modelRef.result.then((result) => {
      if (result.reset) {
        this.templateEditor.disableEditor();
        this.templateReset = true;
        this.templateGeneratorService.handlePublicTemplateSelected({templateId: this.template.source_template_id});
      } else {
        this.templateEditor.disableEditor();
        this.replaceTemplate(result.data);
      }
    }, (reason) => {
    });
  }

  changingInfluencerType(e) {
    this.saveTemplateForm.patchValue({'influencer_type_id': e});
  }

  showNewEditor() {
    this.newEditor.show = true;
  }

  onEditorStateChanged(event: { action: string; body: any; bodyJson: string; visualEditorId: any; }) {
    if (event.action === 'save') {
      this.templateData = {
        body: event.body,
        bodyJson: event.bodyJson ? JSON.parse(event.bodyJson) : null,
        visualEditorId: event.visualEditorId,
        campaignId: this.template.campaign_id,
        influencerTypeId: this.template.influencer_type_id,
        templateId: this.template.id,
        templateTypeId: this.template.template_type_id
      };
      this.newEditor.active = true;
    }
    this.newEditor.show = false;
  }

  ngOnDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    this.templateEditor.destroy();
    this.templateGeneratorService.unsubscribe();
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
    this.subscription.unsubscribe();
  }
}
