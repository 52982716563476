import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import {SegmentApiService} from '@app/services/api/segment-api.service';
import * as routes from '@app/routes';
import {select, Store} from '@ngrx/store';
import {
  CampaignUpdateError,
  CampaignUpdateSuccess,
  getCampaignUpdate,
  getFileUploadProgress,
  getSignedUrl,
  getSignedUrlError,
  getUploadToSignedUrl,
  getUploadToSignedUrlError,
  getWidgetAvatarTemplates,
  ICampaignState,
  IFileUploadState, ITemplateState,
  IVariableState,
  ResetFileState,
  SignedUrl,
  UploadToSignedUrl,
  WidgetAvatarTemplateListAll
} from '@app/stores';
import {Observable} from 'rxjs';
import {LoaderService} from '@app/services/loader.service';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {SocketService} from '@app/services/socket.service';
import * as _ from 'lodash';
import {ImageCropperDialogComponent} from '@app/modules/image-cropper-dialog/image-cropper-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {CampaignService} from '@app/services/campaign.service';
import {WidgetAvatarTemplatesComponent} from "@app/modules/project/widget-avatar-templates/widget-avatar-templates.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-share-campaign.component',
  templateUrl: './share-campaign.component.html',
  styleUrls: ['./share-campaign.component.scss']
})
export class ShareCampaignComponent implements OnInit, OnDestroy, AfterViewInit {
  public step;
  public campaign: any;
  public landingTemplate;
  public campaignMeta: any = {};
  public socialMedias: any = {};

  public variables: any = {};
  public variablesFor = '';
  public isForMeta = false;
  public readonly currentSocialSharePlatforms = ['facebook', 'twitter', 'linkedin', 'whatsapp', 'slack', 'instagram', 'email', 'facebook_messenger', 'microsoft_teams', 'sms', 'zalo', 'line', 'wechat', 'bluesky'];

  public sectionsStatus = { 'campaign': true, 'content': true, 'widget': true };
  public socialMessageError = {
    facebook: false,
    twitter: false,
    linkedin: false,
    whatsapp: false,
    slack: false,
    instagram: false,
    email: false,
    facebook_messenger: false,
    microsoft_teams: false,
    sms: false,
    zalo: false,
    line: false,
    wechat: false,
    bluesky: false
  };
  public socialPlatformVisibility = {
    facebook: true,
    twitter: true,
    linkedin: true,
    whatsapp: true,
    slack: true,
    instagram: true,
    email: true,
    facebook_messenger: true,
    microsoft_teams: true,
    sms: true,
    zalo: false,
    line: false,
    wechat: false,
    bluesky: false
  };
  public currentSocialMediaPreviewing = 'facebook';
  boxWidth = 30;
  boxHeight = 30;
  columns = 10;
  positions = [
    { id: 'facebook', x: 10, y: 0, active: true, order: 0 },
    { id: 'twitter', x: 40, y: 0, active: true, order: 1 },
    { id: 'linkedin', x: 70, y: 0, active: true, order: 2 },
    { id: 'whatsapp', x: 100, y: 0, active: true, order: 3 },
    { id: 'slack', x: 130, y: 0, active: true, order: 4 },
    { id: 'instagram', x: 160, y: 0, active: true, order: 5 },
    { id: 'email', x: 190, y: 0, active: true, order: 6 },
    { id: 'facebook_messenger', x: 220, y: 0, active: true, order: 7 },
    { id: 'microsoft_teams', x: 250, y: 0, active: true, order: 8 },
    { id: 'sms', x: 280, y: 0, active: true, order: 9 },
    { id: 'zalo', x: 10, y: 30, active: false, order: 10 },
    { id: 'line', x: 40, y: 30, active: false, order: 11 },
    { id: 'wechat', x: 70, y: 30, active: false, order: 12 },
    { id: 'bluesky', x: 100, y: 30, active: true, order: 13 },
  ];
  loader$: Observable<boolean>;
  unsubscriber = new Subject();
  subscription: any;
  previewClicked = false;
  uploadTab = 'image';
  videoURL = '';
  videoWidth = '';
  videoHeight = '';
  contentType = '';
  isVideoUploaded = false;
  contentFile = null;
  contentSignedUrl = null;
  fileUploadProgress = 0;
  socialShareInfo = false;
  alreadyVisitedTabs: string[] = [this.currentSocialMediaPreviewing];
  socialShareSkip = '';
  widgetAIAvatarTemplates = [];
  selectedWidgetAIAvatarTemplate = null;
  functionToCallAvterSave = () => {
  };

  private readonly aspectRatio: number = null;
  private readonly portrait: number | boolean = false;
  private readonly landscape: number | boolean = false;

  @ViewChild('message') message: ElementRef;
  @ViewChild('description') description: ElementRef;
  public widget_Settings;

  constructor(
    private router: Router,
    private segmentsApiService: SegmentApiService,
    private templateGeneratorService: TemplateGeneratorService,
    private variableStore: Store<IVariableState>,
    private campaignStore: Store<ICampaignState>,
    private loaderService: LoaderService,
    private fileUploadStore: Store<IFileUploadState>,
    private toastr: ToastrService,
    private socketService: SocketService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef,
    private campaignService: CampaignService,
    private templateStore: Store<ITemplateState>,
    private _modalService: NgbModal
  ) {
    this.setDefault();
    this.fileUploadStore.dispatch(ResetFileState({ params: { error: '', file: null } }));
    this.subscribeStore();
    this.subscription = this.socketService.sourceData
      .subscribe((message: any) => {
          if (message?.data?.requesterId) {
            if (message.data?.requesterType === 'template') {
              if (this.campaign) {
                this.campaign = _.cloneDeep(this.campaign);
                if (!this.campaign.social_template) {
                  this.campaign.social_template = {};
                }
                if (this.campaign.social_template?.id === message?.data?.requesterId) {
                  this.campaign.social_template.thumbnail = message.data.result.thumbnail;
                }
              }
            }
          }
        },
        (err) => console.error(err),
        () => console.warn('Completed!')
      );
  }

  subscribeStore() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.contentSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.contentSignedUrl) {
          const pathUrl = this.contentSignedUrl.split('?')[0];
          this.campaignMeta = {
            ...this.campaignMeta,
            ...(this.isVideoUploaded ? {
              video: pathUrl,
              image: ''
            } : {
              image: pathUrl,
              video: ''
            }),
            image_template: false
          };
          this.isVideoUploaded = false;
          this.contentSignedUrl = null;
          this.contentFile = null;
          this.saveData().then();
        }
      });

    this.templateStore.pipe(select(getWidgetAvatarTemplates))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(widgetAvatarTemplates => {
        if (widgetAvatarTemplates && widgetAvatarTemplates.length > 0) {
          this.widgetAIAvatarTemplates = widgetAvatarTemplates.map((item) => ({
            ...item,
            selectedImageVariation: item?.image_variations?.find(image => image.isActive)
          }));
          this.selectedWidgetAIAvatarTemplate = (this.campaignMeta.hasOwnProperty('widget_ai_avatar_template') && this.campaignMeta['widget_ai_avatar_template']) ?
            this.widgetAIAvatarTemplates.find(item => item.id === this.campaignMeta['widget_ai_avatar_template']) : null;
        }
      });
  }

  ngOnInit() {
    this.templateGeneratorService.forceReloadCampaign();
    this.templateGeneratorService.subscribeStore();
    this.loader$ = this.loaderService.loader$;
    this.subscribeToCampaign();
    this.socialShareSkip = localStorage.getItem('skip');
  }

  ngAfterViewInit() {
    this.autoGrow(this.message.nativeElement);
    this.autoGrow(this.description.nativeElement);

    this.message.nativeElement.addEventListener('keypress', (ev) => {
      this.socialShareInfo = true;
    }, { once: true });
  }

  getCurrentSocialMediaPreviewing() {
    return this.currentSocialMediaPreviewing?.length ? this.currentSocialMediaPreviewing?.split('_').join(' ') : this.currentSocialMediaPreviewing;
  }

  subscribeToCampaign() {
    this.campaignStore.pipe(select(getCampaignUpdate))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(campaign => {
        if (campaign) {
          if (this.previewClicked) {
            this.previewClicked = false;
            this.patchValues(campaign);
            this.templateGeneratorService.widgetPreview(campaign.id);
          }
        }
      });
    this.templateGeneratorService.onCampaignLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(async campaign => {
        if (!campaign) {
          return;
        }
        this.campaign = { ...campaign };
        if (campaign.meta) {
          this.patchValues(campaign);
        } else {
          this.setDefault();
        }
        if (this.campaign?.project_id && this.campaignMeta['allow_influencers_to_use_ai_avatar_in_widget']) {
          this.fetchWidgetAITemplates();
        }
        this.validateSocialMessage();
        const segment = this.campaign.segments[0];
        this.templateGeneratorService.loadVariables({
          influencerTypeId: segment.influencer_type_id,
          campaignId: this.campaign.id
        }, (vars) => {
          const result = [];
          Object.entries(vars).forEach(([key, value]) => {
            if (value.hasOwnProperty('group') && value['group']) {
              if (!result[value['group']]) {
                result[value['group']] = [];
              }
              result[value['group']].push({
                name: value['title'].replace(value['group'].toString().toLowerCase().trim(), ''),
                key: value['alias'] ? `#${value['alias']}#` : value['key'],
                valueType: value['valueType']
              });
            } else {
              if (!result['generic']) {
                result['generic'] = [];
              }
              result['generic'].push({
                name: value['title'],
                key: value['key'],
                valueType: value['valueType']
              });
            }
          });
          this.variables = result;
        });
        this.initStep();

        // this.templateGeneratorService.loadClientTemplate({ templateTypeId: LANDING_PAGE_TEMPLATE_TYPE_ID },
        //   { createIfNotExist: false, forceTouch: false });
        // this.templateGeneratorService.onTemplateChanged[LANDING_PAGE_TEMPLATE_TYPE_ID]
        //   .pipe(takeUntil(this.unsubscriber))
        //   .subscribe((template) => {
        //     if (!template) {
        //       return;
        //     }
        //     this.landingTemplate = template;
        //   });
      });
  }

  patchValues(campaign) {
    this.campaignMeta = {
      ...this.campaignMeta,
      ...((typeof campaign.meta === 'string') ? JSON.parse(campaign.meta) : campaign.meta)
    };
    this.socialPlatformVisibility = {
      ...this.socialPlatformVisibility,
    };

    const socialNetworksKeys = Object.keys(this.campaignMeta.social_networks);
    socialNetworksKeys.forEach((key) => {
      if (this.campaignMeta.social_networks?.[key]?.hasOwnProperty('active')) {
        this.socialPlatformVisibility[key] = this.campaignMeta.social_networks[key]['active'];
      } else {
        this.campaignMeta.social_networks[key]['active'] = this.socialPlatformVisibility[key];
      }
    });
    this.widget_Settings = typeof campaign.project.widget_settings === 'string' ? {...JSON.parse(campaign.project.widget_settings)} : {...campaign.project.widget_settings};
    const { facebook, linkedin, twitter, whatsapp, slack, instagram, email, facebook_messenger, microsoft_teams, sms, zalo, line, wechat, bluesky } = this.campaignMeta.social_networks;
    this.socialMedias = {
      ...this.socialMedias,
      facebook: {
        ...this.socialMedias.facebook,
        ...facebook
      },
      linkedin: {
        ...this.socialMedias.linkedin,
        ...linkedin
      },
      twitter: {
        ...this.socialMedias.twitter,
        ...twitter
      },
      whatsapp: {
        ...this.socialMedias.whatsapp,
        ...whatsapp
      },
      slack: {
        ...this.socialMedias.slack,
        ...slack
      },
      instagram: {
        ...this.socialMedias.instagram,
        ...instagram
      },
      email: {
        ...this.socialMedias.email,
        ...email
      },
      facebook_messenger: {
        ...this.socialMedias.facebook_messenger,
        ...facebook_messenger
      },
      microsoft_teams: {
        ...this.socialMedias.microsoft_teams,
        ...microsoft_teams
      },
      sms: {
        ...this.socialMedias.sms,
        ...sms
      },
      zalo: {
        ...this.socialMedias.zalo,
        ...zalo
      },
      line: {
        ...this.socialMedias.line,
        ...line
      },
      wechat: {
        ...this.socialMedias.wechat,
        ...wechat
      },
      bluesky: {
        ...this.socialMedias.bluesky,
        ...bluesky
      }
    };
    this.positions.forEach(pos => {
      pos.x = this.socialMedias[pos.id].x ? this.socialMedias[pos.id].x : pos.x;
      pos.y = this.socialMedias[pos.id].y ? this.socialMedias[pos.id].y : pos.y;
      pos.active = this.socialMedias[pos.id].active ? this.socialMedias[pos.id].active : pos.active;
      pos.order = this.socialMedias[pos.id].order ? this.socialMedias[pos.id].order : pos.order;
    });
    setTimeout(() => {
      this.autoGrow(this.message.nativeElement);
      this.autoGrow(this.description.nativeElement);
    }, 200);
  }

  validateSocialMessage() {
    const validationString = '#InfluencerCampaignPageUrl#';
    this.socialMessageError = {
      facebook: false,
      twitter: false,
      linkedin: false,
      whatsapp: false,
      slack: false,
      instagram: false,
      email: false,
      facebook_messenger: false,
      microsoft_teams: false,
      sms: false,
      zalo: false,
      line: false,
      wechat: false,
      bluesky: false
    };
    let isValid = true;
    this.currentSocialSharePlatforms.every(item => {
      if (!this.socialMedias[item].message.toLowerCase().includes(validationString.toLowerCase())) {
        this.socialMessageError[item] = true;
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  }

  setDefault() {
    this.campaignMeta = {
      description: 'Invite your connections. #ProjectTitle# is an event not to be missed.',
      title: 'Claim Your Seat.',
      image: '',
      video: '',
      video_width: '',
      video_height: '',
      image_template: false
    };
    this.socialMedias = {
      facebook: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      linkedin: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      twitter: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      whatsapp: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      slack: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      instagram: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      email: {
        title: '',
        message: `Hi,

Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#

Best,
#InfluencerFName#`
      },
      facebook_messenger: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      microsoft_teams: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      sms: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      zalo: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      line: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      wechat: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      },
      bluesky: {
        message: `Join me in attending #ProjectHashtag# where professionals will learn and network on aligning strategies to business priorities and opportunities. Register today! #InfluencerCampaignPageUrl#`
      }
    };

    this.cdr.markForCheck();
    setTimeout(() => {
      this.autoGrow(this.message.nativeElement);
      this.autoGrow(this.description.nativeElement);
    }, 200);
  }

  initStep() {
    const params = {};

    params['projectId'] = this.campaign.project_id;
    params['campaignId'] = this.campaign.id;
    params['campaignName'] = this.campaign.name;

    this.step = { title: 'Share', params };
  }

  async onSocialMediaImageSelect(event) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      const { target: { files } } = event;
      let uploadFile = files[0];
      const contentType = files[0].name.split('.').pop();
      if (uploadFile.type.includes('image')) {
        this.isVideoUploaded = false;
        if (contentType !== 'gif') {
          // Crop file
          const croppedImage: File | boolean = await this.cropImage(files[0]);
          // validate
          if (croppedImage === false) {
            // Clear file
            return;
          }
          uploadFile = croppedImage;
        }
      } else if (uploadFile.type.includes('video')) {
        this.isVideoUploaded = true;
        if (!await this.validateVideo(uploadFile, contentType)) {
          return;
        }
      } else {
        this.isVideoUploaded = false;
        this.toastr.error('Only image or video files are allowed');
        return;
      }
      // Clear file
      this.contentFile = uploadFile;
      this.fileUploadStore.dispatch(SignedUrl({ fileName: this.contentFile.name, contentType: this.contentFile.type }));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.contentFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.campaignMeta = {
          ...this.campaignMeta,
          ...(this.isVideoUploaded ? {
            video: data,
            image: ''
          } : {
            image: data,
            video: ''
          }),
          image_template: false
        };
      });
    }
  }

  addVideoUrl() {
    this.contentSignedUrl = null;
    this.contentFile = null;
    this.contentType = 'video';
    this.campaignMeta = {
      ...this.campaignMeta,
      video: this.videoURL,
      video_width: this.videoWidth,
      video_height: this.videoHeight,
      image: '',
      image_template: false
    };
  }

  async validateVideo(uploadFile, contentType) {
    if (contentType !== 'mp4') {
      this.toastr.error('Only video with .mp4 are allowed');
      return false;
    }
    if (this.getVideoFileSize(uploadFile.size) > 512) {
      this.toastr.error('File size should be less then 512 mb');
      return false;
    }
    // const videoInfo: any = await this.getVideoInfo(uploadFile);
    // if (videoInfo.duration > 140) {
    //   this.toastr.error('Video duration must be of 2 minutes and 20 seconds');
    //   return false;
    // }
    // const aspectRatio = videoInfo.height / videoInfo.width;
    // if (aspectRatio < 0.55 || aspectRatio > 0.57) {
    //   this.toastr.error('Video dimension must me 1280 * 720 Or Aspect ratio of 16:9');
    //   return false;
    // }
    return true;
  }

  getVideoInfo(file) {
    return new Promise((resolve, reject) => {
      const info: any = {
        duration: '',
        height: '',
        width: ''
      };
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        info.duration = video.duration;
        info.width = video.videoWidth;
        info.height = video.videoHeight;
        resolve(info);
      };
      video.src = URL.createObjectURL(file);
    });
  }

  getVideoFileSize(size) {
    let i = 0;
    while (size > 900) {
      size /= 1024;
      i++;
    }
    return (Math.round(size * 100) / 100);
  }

  onAddVariablesButtonClick(variablesFor: any) {
    this.isForMeta = false;
    this.variablesFor = variablesFor;
  }

  onMetaVariablesButtonClick(variablesFor: any) {
    this.isForMeta = true;
    this.variablesFor = variablesFor;
  }

  updateValue(event, textFor) {
    // this.socialMedias[this.currentSocialMediaPreviewing]['page'][textFor] = event.target.value;
    this.campaignMeta[textFor] = event.target.value;
  }

  onVariableClick(variable, element = null) {
    if (this.isForMeta) {
      if (!this.campaignMeta[this.variablesFor]) {
        this.campaignMeta[this.variablesFor] = '';
      }
      const value = this.campaignMeta[this.variablesFor];
      const varValue = variable.key;
      if (element && element.selectionStart || element.selectionStart === '0' || element.selectionStart === 0) {
        const startPos = element.selectionStart;
        const endPos = element.selectionEnd;
        if (element.selectionStart === '0' || element.selectionStart === 0) {
          this.campaignMeta[this.variablesFor] = `${value.substring(0, startPos)}${varValue}${value.substring(endPos, value.length)}`;
        } else {
          this.campaignMeta[this.variablesFor] = `${value.substring(0, startPos)}${varValue}${value.substring(endPos, value.length)}`;
        }
      } else {
        this.campaignMeta[this.variablesFor] = `${value}${varValue}`;
      }
    } else {
      if (!this.socialMedias[this.currentSocialMediaPreviewing]) {
        this.socialMedias[this.currentSocialMediaPreviewing] = {};
        if (!this.socialMedias[this.currentSocialMediaPreviewing][this.variablesFor]) {
          this.socialMedias[this.currentSocialMediaPreviewing][this.variablesFor] = '';
        }
      }
      const value = this.socialMedias[this.currentSocialMediaPreviewing][this.variablesFor];
      const varValue = variable.key;
      if (element && element.selectionStart || element.selectionStart === '0' || element.selectionStart === 0) {
        const startPos = element.selectionStart;
        const endPos = element.selectionEnd;
        if (element.selectionStart === '0' || element.selectionStart === 0) {
          this.socialMedias[this.currentSocialMediaPreviewing][this.variablesFor] = `${value.substring(0, startPos)}${varValue}${value.substring(endPos, value.length)}`;
        }
        else{
          this.socialMedias[this.currentSocialMediaPreviewing][this.variablesFor] = `${value.substring(0, startPos)}${varValue}${value.substring(endPos, value.length)}`;
        }
      } else {
        this.socialMedias[this.currentSocialMediaPreviewing][this.variablesFor] = `${value}${varValue}`;
      }
    }
    this.variablesFor = '';
    this.validateSocialMessage();
  }

  getPreText() {
    return this.currentSocialMediaPreviewing === 'email' ? 'Message' : (this.getCurrentSocialMediaPreviewing() + ' Post');
  }

  changeCheckBox(event) {
    this.socialPlatformVisibility[this.currentSocialMediaPreviewing] = event.target.checked;
  }

  onBackButonClick() {
    if (this.campaign.landing_page_status === 'skipped') {
      this.router.navigateByUrl(routes.addInfluencers({ projectId: this.campaign.project_id }, { campId: this.campaign.id }));
    } else {
      this.router.navigateByUrl(
        routes.campaignTemplate({
          projectId: this.campaign.project_id,
          campaignId: this.campaign.id
        })
      );
    }
  }

  onSave(callBack = () => {
  }) {
    this.functionToCallAvterSave = callBack;
    if (this.contentSignedUrl && this.contentFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({
        files: [{
          url: this.contentSignedUrl,
          fileData: this.contentFile
        }]
      }));
    } else {
      this.savePostion();
      this.saveData().then();
    }
  }

  async saveData() {
    let metaValues = {
      ...this.campaignMeta,
      social_networks: this.socialMedias
    };
    // const socialMediaKeys = Object.keys(metaValues.social_networks);
    // socialMediaKeys.forEach((key) => {
    //    metaValues.social_networks[key]['active'] = this.socialPlatformVisibility[key];
    // });
    this.positions.forEach(pos => {
      metaValues.social_networks[pos.id]['order'] = pos.order;
      metaValues.social_networks[pos.id]['active'] = this.socialPlatformVisibility[pos.id];
      metaValues.social_networks[pos.id]['x'] = pos.x;
      metaValues.social_networks[pos.id]['y'] = pos.y;
    })
    // this.campaignStore.dispatch(CampaignUpdate({campaign: {meta: metaValues}, campaignId: this.campaign.id}));

    this.loaderService.show();
    return await this.campaignService.updateCampaign({ meta: metaValues }, this.campaign.id).pipe(
      map((resp: any) => {
        if (resp.success) {
          this.loaderService.hide();
          this.functionToCallAvterSave();
          return this.campaignStore.dispatch(CampaignUpdateSuccess({ campaign: resp.data }));
        }
        this.loaderService.hide(true);
        return this.campaignStore.dispatch(CampaignUpdateError({ error: this.loaderService.getErrorMessage(resp) }));
      }),
      catchError(error => {
        this.loaderService.hide(true);
        return of(this.campaignStore.dispatch(CampaignUpdateError({ error: this.loaderService.getErrorMessage(error) })));
      })
    ).toPromise();
  }

  async onSaveButtonClick() {
    if (!this.validateSocialMessage()) {
      this.changeToInvalidTab();
      return false;
    }
    this.onSave(() => {
      this.router.navigateByUrl('/' + routes.campaigns({ projectId: this.campaign.project_id }));
    });
  }

  personalizeImage() {
    this.router.navigate(['/projects', this.campaign.project_id, 'campaigns', this.campaign.id, 'personalize', 'social-image']);
  }

  async onNextButtonClick() {
    if (!this.validateSocialMessage()) {
      this.changeToInvalidTab();
      return false;
    }
    this.onSave(() => {
      this.router.navigateByUrl(
        routes.campaignPreview({
          projectId: this.campaign.project_id,
          campaignId: this.campaign.id
        })
      );
    });
  }

  changeToInvalidTab() {
    const validationString = '#InfluencerCampaignPageUrl#';
    this.currentSocialSharePlatforms.every(item => {
      if (!this.socialMedias[item].message.toLowerCase().includes(validationString.toLowerCase())) {
        this.currentSocialMediaPreviewing = item;
        return false;
      }
      return true;
    });
  }

  getTitle() {
    if (this.socialMedias[this.currentSocialMediaPreviewing].hasOwnProperty('title') &&
      this.socialMedias[this.currentSocialMediaPreviewing]['title']) {
      return this.socialMedias[this.currentSocialMediaPreviewing]['title'];
    } else {
      return this.campaignMeta?.title;
    }
  }

  getImage() {
    this.contentType = 'image';
    let image = this.campaignMeta?.image;
    if (this.campaignMeta.hasOwnProperty('image_template') && this.campaignMeta.image_template) {
      image = this.campaign?.social_template?.thumbnail;
    } else {
      if (this.campaignMeta?.video) {
        this.contentType = 'video';
        image = this.campaignMeta?.video;
      }
    }
    return image;
  }

  getDescription() {
    return this.campaignMeta?.description;
  }

  getMessage() {
    if (this.socialMedias[this.currentSocialMediaPreviewing].hasOwnProperty('message') &&
      this.socialMedias[this.currentSocialMediaPreviewing]['message']) {
      return this.socialMedias[this.currentSocialMediaPreviewing]['message'];
    }
    return '';
  }

  applyToOthers() {
    const patchData = this.socialMedias[this.currentSocialMediaPreviewing];
    this.socialMedias = {
      ...this.socialMedias,
      facebook: {
        ...this.socialMedias.facebook,
        ...patchData
      },
      linkedin: {
        ...this.socialMedias.linkedin,
        ...patchData
      },
      twitter: {
        ...this.socialMedias.twitter,
        ...patchData
      },
      whatsapp: {
        ...this.socialMedias.whatsapp,
        ...patchData
      },
      slack: {
        ...this.socialMedias.slack,
        ...patchData
      },
      instagram: {
        ...this.socialMedias.instagram,
        ...patchData
      },
      email: {
        ...this.socialMedias.email,
        ...patchData
      },
      facebook_messenger: {
        ...this.socialMedias.facebook_messenger,
        ...patchData
      },
      microsoft_teams: {
        ...this.socialMedias.microsoft_teams,
        ...patchData
      },
      sms: {
        ...this.socialMedias.sms,
        ...patchData
      },
      zalo: {
        ...this.socialMedias.zalo,
        ...patchData
      },
      line: {
        ...this.socialMedias.line,
        ...patchData
      },
      wechat: {
        ...this.socialMedias.wechat,
        ...patchData
      },
      bluesky: {
        ...this.socialMedias.bluesky,
        ...patchData
      }
    };
    this.validateSocialMessage();
  }

  autoGrow(element) {
    element.style.height = '5px';
    element.style.height = (element.scrollHeight + 15) + 'px';
  }

  changeSocialMediaPreview(socialMedia) {
    this.currentSocialMediaPreviewing = socialMedia;
    setTimeout(() => {
      this.autoGrow(this.message.nativeElement);
      this.autoGrow(this.description.nativeElement);
    }, 200);

    if (this.alreadyVisitedTabs.indexOf(socialMedia) === -1) {
      this.message.nativeElement.addEventListener('keypress', (ev) => {
        this.socialShareSkip = localStorage.getItem('skip');
        this.socialShareInfo = true;
        this.alreadyVisitedTabs.push(socialMedia);
      }, { once: true });
    }
  }

  onSkip() {
    localStorage.setItem('skip' , 'true');
    this.socialShareInfo = false;
  }

  async previewSocialShare() {
    if (!this.validateSocialMessage()) {
      this.changeToInvalidTab();
      return false;
    }
    this.previewClicked = true;
    this.onSave();
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
    this.subscription.unsubscribe();
    this.templateGeneratorService.unsubscribe();
  }

  async cropImage(file: File): Promise<File | boolean> {
    return new Promise((resolve, reject) => {
      //
      // Set element and file reader
      let reader = new FileReader();

      //
      // On load
      reader.onloadend = (e) => {
        let dialogRef = this.dialog.open(ImageCropperDialogComponent, {
          width: '700px',
          data: {
            file: reader.result,
            filename: file.name,
            cropConfig: {
              aspectRatio: this.aspectRatio,
              portrait: this.portrait,
              landscape: this.landscape
            }
          }
        });

        dialogRef.afterClosed().subscribe((data) => {
          //
          // Validate
          if (!data || data.success !== true) {
            return resolve(false);
          }

          return resolve(data.file);
        });
      };

      //
      // Read file
      reader.readAsDataURL(file);
    });
  }

  applyRTLEmbedding(element = null, textFor, currentSocialMediaPreviewing = '') {
    if (element) {
      const cursorPosition = element.selectionStart;
      const inputText = element.value;
      const convertedText = inputText.slice(0, cursorPosition) + '\u202B' + inputText.slice(cursorPosition);
      textFor === 'social' ? this.socialMedias[currentSocialMediaPreviewing]['message'] = convertedText : this.campaignMeta[textFor] = convertedText;
      element.value = convertedText;
    }
  }

  showCustomizeAvatarTemplatePopup() {
    this.cdr.detectChanges();
    const data = {
      project_id: this.campaign.project_id
    };
    const modelRef: any = this._modalService.open(WidgetAvatarTemplatesComponent, {
      centered: false,
      size: 'xl',
      keyboard: true
    });
    modelRef.componentInstance.data = data;
    modelRef.result.then((result) => {
    }, (reason) => {
      this.fetchWidgetAITemplates()
    });
  }

  fetchWidgetAITemplates() {
    const orderBy = {'column': 'created_at', 'dir': 'desc'};
    let options = {orderBy};
    setTimeout(() => {
      this.templateStore.dispatch(WidgetAvatarTemplateListAll({
        params: {
          options: JSON.stringify(options),
          project_id: this.campaign.project_id
        }
      }));
    }, 500);
  }

  selectWidgetAIAvatarTemplate(template) {
    this.campaignMeta['widget_ai_avatar_template'] = template.id;
    this.selectedWidgetAIAvatarTemplate = template;
  }

  // setInactive(positionId) {
  //   // const positionIndex = this.positions.findIndex((icon) => icon.id === positionId);
  //   // if (positionIndex !== -1) {
  //   //   const [position] = this.positions.splice(positionIndex, 1); // Remove the element from its current position
  //   //   position.active = false; // Mark it as inactive
  //   //   this.positions.push(position); // Add it to the end of the array
  //   //   this.rearrangePositions();
  //   // }
  //   const position = this.positions.find((icon) => icon.id === positionId);
  //   if (position) {
  //     position.active = false; // Mark as inactive
  //   }
  // }
  //
  // rearrangePositions() {
  //   const activePositions = this.positions.filter((pos) => pos.active);
  //   activePositions.forEach((position, index) => {
  //     position.x = ((index % this.columns) * 30) + 10; // 160 is spacing for each column
  //     position.y = Math.floor(index / this.columns) * 30; // 100 is spacing for each row
  //   });
  //   console.log(this.positions)
  // }

  onMoving(event, currentBox) {
    if (!currentBox.active) return;
    const currentPosition = this.positions.find(
      (pos) => pos.id === currentBox.id
    );

    for (let position of this.positions) {
      if (position.id !== currentBox.id && position.active) {
        const withinXRange =
          event.x + this.boxWidth / 2 >= position.x &&
          event.x + this.boxWidth / 2 <= position.x + this.boxWidth;
        const withinYRange =
          event.y + this.boxHeight / 2 >= position.y &&
          event.y + this.boxHeight / 2 <= position.y + this.boxHeight;

        // Swap positions
        if (withinXRange && withinYRange) {
          const temp = { x: position.x, y: position.y };
          position.x = currentPosition.x;
          position.y = currentPosition.y;
          currentPosition.x = temp.x;
          currentPosition.y = temp.y;
          break;
        }
      }
    }
  }

  savePostion() {
    const sortedPositions = [...this.positions].sort((a, b) => {
      if (a.y === b.y) {
        return a.x - b.x; // If y values are equal, sort by x
      }
      return a.y - b.y; // Otherwise, sort by y
    });
    sortedPositions.forEach((sortedPosition, index) => {
      this.positions.find((position) => position.id === sortedPosition.id)[
        'order'
        ] = index + 1;
    });
  }
}
