<app-new-editor
  *ngIf="newEditor.show"
  [template]="templateData"
  [variables]="variables"
  [title]="'Social Share Editor'"
  (EditorStateChanged)="onEditorStateChanged($event)"></app-new-editor>
<div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
  <div class="rightBody">
    <app-status-bar [customStyle]="{'margin-left': '19rem'}" [loading]="loader$ | async" [step]="step"
                    [skipLanding]="campaign?.landing_page_status === 'skipped'"
                    (SaveButtonClick)="onSaveButtonClick()">
    </app-status-bar>
    <app-template-editor
      *ngIf="!newEditor.active"
      [customClasses]="'mt-3'"
      [template]="template" [viewportSize]="viewportSize"
      (OnTemplateLoaded)="templateLoaded($event)"
      (OnPageSettingsChanged)="onStylesCustomizationChange($event)"
      #templateEditor [pageSetting]="stylesForm.value" [variables]="variables"
      (OnTemplateTypeChange)="fetchRawTemplatesById($event)" [showTemplateSelector]="true" [showBlockSelector]="false"
      [rawTemplatesTypes]="rawTemplatesTypes" [templateType]="'socialShare'" [templates]="templates"></app-template-editor>
  </div>
</div>
<div class="operation-box">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md-4">
        <div class="text-left">
          <button class="btn btn-default mr-2" (click)="onBackButtonClick()" type="button">
            Back
          </button>
          <button *ngIf="!isAdmin" class="btn btn-default" [disabled]="!this.templateId" (click)="saveAsNewTemplateModel(saveAsTemplate, 'client')" type="button" [title]="(!this.templateId ?'Please save the template first and then edit again for this button to become enabled':'')">
            Save as template
          </button>
          <div  *ngIf="isAdmin" class="btn-group dropup mr-2">
            <button type="button"
                    [disabled]="(loader$ | async) || !isTemplateLoaded || !this.templateId"
                    class="btn btn-default dropdown-toggle"
                    [title]="(!this.templateId ?'Please save the template first and then edit again for this button to become enabled':'')"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Template
            </button>
            <div class="dropdown-menu border-rad-10x">
              <button [disabled]="(loader$ | async) || !isTemplateLoaded"
                      (click)="saveAsNewTemplateModel(saveAsTemplate, 'client')"
                      class="dropdown-item" type="button">
                Save as template
              </button>
              <button [disabled]="(loader$ | async) || !isTemplateLoaded"
                      class="dropdown-item"
                      (click)="saveAsNewTemplateModel(saveAsTemplate, 'public')">
                Save as public template
              </button>
            </div>
          </div>
          <button [disabled]="!isTemplateLoaded" *ngIf="templateId"
                  class="btn btn-default mr-2"
                  (click)="showTemplateRevisions()">
            Undo All
          </button>
          <button [disabled]="!isTemplateLoaded" *ngIf="!newEditor.active && this.isAdmin"
                  class="btn btn-default"
                  (click)="showNewEditor()">
            New Editor (Do Not Use Yet)
          </button>
        </div>
      </div>
      <div class="col-md-4 d-flex justify-content-center">
        <app-device-toolbar
          (onPageSizeChange)="onPageSizeSelect($event)"
          (openCodeEditor)="onEditHtml()"></app-device-toolbar>
      </div>
      <div class="col-md-4">
        <div class="text-right">
          <button [disabled]="!isTemplateLoaded" (click)="saveClientTemplate()"
                  class="btn btn-primary mr-2">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #saveAsTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Save template as</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="templateForm">
    <div class="row">
      <div class="col-md-12">
        <div class='input-group input-group-sm'>
          <input class="form-control" formControlName="saveAsTemplateName" placeholder="Template Name" [style]="{background: 'none'}"
                 type="text" [ngClass]="{ 'is-invalid': saveAsTemplateNameSubmit && !saveAsTemplateName }">
        </div>
        <div class="bar error-brd" *ngIf="!saveAsTemplateName && saveAsTemplateNameSubmit">
          <div class="invalid-text">Enter template name</div>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div class="col-md-12">
          <div class="d-flex align-items-center">
            <small>Influencer Type</small>
          </div>
          <div class='input-group input-group-sm'>
            <select class="floating-select" formControlName="influencer_type_id" (ngModelChange)='changingInfluencerType($event)'
                    [ngClass]="{ 'is-invalid': submitted}">
              <option [value]='influencerType.id'
                      *ngFor="let influencerType of influencerTypes">{{influencerType.name}}</option>
            </select>
          </div>
      </div>
    </div>
    </form>
  </div>
  <input type="hidden" ngbAutofocus>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.close()">Close</button>
    <button type="button" class="btn btn-primary" (click)="saveAsNewTemplate(modal)">Submit</button>
  </div>
</ng-template>
