<div class="page-designer-toolbar">
  <div class="page-designer-toolbar-container" cdkDropListGroup>
    <div class='p-0 bg-white h-100 container-container'>
      <div class='w-100'>
        <div class='bg-light sidebar-accordion social-networks-icons-tab'>
          <div class="d-flex flex-wrap">
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[0])"
                [position]="{ x: positions[0].x, y: positions[0].y }"
              >
              <button (click)="changeSocialMediaPreview('facebook')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'facebook' && this.socialPlatformVisibility['facebook'],
                    disable: !this.socialPlatformVisibility['facebook'] }"
                      class='btn mx-auto'>
                <i class='fa fa-facebook' title="Facebook"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[1])"
                [position]="{ x: positions[1].x, y: positions[1].y }"
              >
              <button (click)="changeSocialMediaPreview('twitter')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'twitter' && this.socialPlatformVisibility['twitter'],
                    disable: !this.socialPlatformVisibility['twitter'], disableActive: currentSocialMediaPreviewing == 'twitter'  }"
                      class='btn mx-auto'>
                <i class='fa-brands fa-x-twitter' title="Twitter"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[2])"
                [position]="{ x: positions[2].x, y: positions[2].y }"
              >
              <button (click)="changeSocialMediaPreview('linkedin')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'linkedin' && this.socialPlatformVisibility['linkedin'],
                    disable: !this.socialPlatformVisibility['linkedin'] }"
                      class='btn mx-auto'>
                <i class='fa fa-linkedin' title="Linkedin"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[3])"
                [position]="{ x: positions[3].x, y: positions[3].y }"
              >
              <button (click)="changeSocialMediaPreview('whatsapp')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'whatsapp' && this.socialPlatformVisibility['whatsapp'],
                    disable: !this.socialPlatformVisibility['whatsapp'] }"
                      class='btn mx-auto'>
                <i class='fa fa-whatsapp'  title="Whatsapp"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[4])"
                [position]="{ x: positions[4].x, y: positions[4].y }"
              >
              <button (click)="changeSocialMediaPreview('slack')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'slack' && this.socialPlatformVisibility['slack'],
                    disable: !this.socialPlatformVisibility['slack'] }"
                      class='btn mx-auto'>
                <i class='fa fa-slack' title="Slack"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[5])"
                [position]="{ x: positions[5].x, y: positions[5].y }"
              >
              <button (click)="changeSocialMediaPreview('instagram')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'instagram' && this.socialPlatformVisibility['instagram'],
                    disable: !this.socialPlatformVisibility['instagram'] }"
                      class='btn mx-auto'>
                <i class='fa fa-instagram' title="Instagram"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[6])"
                [position]="{ x: positions[6].x, y: positions[6].y }"
              >
              <button (click)="changeSocialMediaPreview('email')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'email' && this.socialPlatformVisibility['email'],
                      disable: !this.socialPlatformVisibility['email'] }"
                      class='btn mx-auto'>
                <i class='fa fa-envelope' title="Email"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[7])"
                [position]="{ x: positions[7].x, y: positions[7].y }"
              >
              <button (click)="changeSocialMediaPreview('facebook_messenger')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'facebook_messenger' && this.socialPlatformVisibility['facebook_messenger'] }"
                      class='btn mx-auto social-share-btn-icon'>
                <svg [ngClass]="{ svg_disable : !this.socialPlatformVisibility['facebook_messenger'] }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <title>Facebook messenger</title>
                  <path
                    d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z"/>
                </svg>
              </button>
              </div>
            </div>


            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[8])"
                [position]="{ x: positions[8].x, y: positions[8].y }"
              >
              <button (click)="changeSocialMediaPreview('microsoft_teams')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'microsoft_teams' && this.socialPlatformVisibility['microsoft_teams'] }"
                      class='btn mx-auto social-share-btn-icon'>
                <svg  [ngClass]="{ svg_disable : !this.socialPlatformVisibility['microsoft_teams'] }" class="team-icon" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2228.83 2073.33" xml:space="preserve">
                  <title>Microsoft teams</title>
                  <path class="st0" d="M1554.64,777.5h575.71c54.39,0,98.48,44.09,98.48,98.48l0,0v524.4c0,199.9-162.05,361.95-361.95,361.95l0,0	h-1.71c-199.9,0.03-361.97-162-362-361.9c0-0.02,0-0.03,0-0.05V828.97C1503.17,800.54,1526.21,777.5,1554.64,777.5L1554.64,777.5z"/>
                  <circle class="st0" cx="1943.75" cy="440.58" r="233.25"/>
                  <circle class="st1" cx="1218.08" cy="336.92" r="336.92"/>
                  <path class="st1" d="M1667.32,777.5H717.01c-53.74,1.33-96.26,45.93-95.01,99.68v598.1c-7.51,322.52,247.66,590.16,570.17,598.05	c322.51-7.89,577.67-275.53,570.17-598.05v-598.1C1763.58,823.43,1721.07,778.83,1667.32,777.5z"/>
                  <path class="st2" d="M1244,777.5v838.15c-0.26,38.44-23.55,72.96-59.09,87.6c-11.32,4.79-23.48,7.25-35.77,7.26H667.61	c-6.74-17.1-12.96-34.21-18.14-51.83c-18.14-59.48-27.4-121.31-27.47-183.49V877.02c-1.25-53.66,41.2-98.19,94.85-99.52H1244z"/>
                  <path class="st3" d="M1192.17,777.5v889.98c0,12.29-2.47,24.45-7.26,35.77c-14.63,35.54-49.16,58.83-87.6,59.09H691.97	c-8.81-17.1-17.1-34.21-24.36-51.83s-12.96-34.21-18.14-51.83c-18.14-59.48-27.4-121.31-27.47-183.49V877.02	c-1.25-53.66,41.2-98.19,94.85-99.52H1192.17z"/>
                  <path class="st3" d="M1192.17,777.5v786.31c-0.4,52.22-42.63,94.46-94.85,94.85H649.47c-18.14-59.48-27.4-121.31-27.47-183.49	V877.02c-1.25-53.66,41.2-98.19,94.85-99.52H1192.17z"/>
                  <path class="st3" d="M1140.33,777.5v786.31c-0.4,52.22-42.63,94.46-94.85,94.85H649.47c-18.14-59.48-27.4-121.31-27.47-183.49	V877.02c-1.25-53.66,41.2-98.19,94.85-99.52H1140.33z"/>
                  <path class="st2" d="M1244,509.52V672.8c-8.81,0.52-17.1,1.04-25.92,1.04s-17.1-0.52-25.92-1.04c-17.5-1.16-34.85-3.94-51.83-8.29	c-104.96-24.86-191.68-98.47-233.25-198c-7.15-16.71-12.71-34.07-16.59-51.83h258.65C1201.45,414.87,1243.8,457.22,1244,509.52z"/>
                  <path class="st3" d="M1192.17,561.35V672.8c-17.5-1.16-34.85-3.94-51.83-8.29c-104.96-24.86-191.68-98.47-233.25-198h190.23	C1149.62,466.7,1191.97,509.05,1192.17,561.35z"/>
                  <path class="st3" d="M1192.17,561.35V672.8c-17.5-1.16-34.85-3.94-51.83-8.29c-104.96-24.86-191.68-98.47-233.25-198h190.23	C1149.62,466.7,1191.97,509.05,1192.17,561.35z"/>
                  <path class="st3" d="M1140.33,561.35V664.5c-104.96-24.86-191.68-98.47-233.25-198h138.4	C1097.78,466.7,1140.13,509.05,1140.33,561.35z"/>
                  <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="198.0988" y1="-1123.0724" x2="942.2333" y2="165.7381" gradientTransform="matrix(1 0 0 1 0 1515.3333)">
                    <stop offset="0" style="stop-color:#5A62C3"/>
                    <stop offset="0.5" style="stop-color:#4D55BD"/>
                    <stop offset="1" style="stop-color:#3940AB"/>
                  </linearGradient>
                  <path class="st4" d="M95.01,466.5h950.31c52.47,0,95.01,42.54,95.01,95.01v950.31c0,52.47-42.54,95.01-95.01,95.01H95.01	c-52.47,0-95.01-42.54-95.01-95.01V561.51C0,509.04,42.54,466.5,95.01,466.5z"/>
                  <path class="st5" d="M820.21,828.19H630.24v517.3H509.21v-517.3H320.12V727.84h500.09V828.19z"/>
                </svg>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[9])"
                [position]="{ x: positions[9].x, y: positions[9].y }"
              >
              <button (click)="changeSocialMediaPreview('sms')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'sms' && this.socialPlatformVisibility['sms'],
                    disable: !this.socialPlatformVisibility['sms'] }"
                      class='btn mx-auto'>
                <i class="fa fa-comments" title="SMS"></i>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[10])"
                [position]="{ x: positions[10].x, y: positions[10].y }"
              >
              <button (click)="changeSocialMediaPreview('zalo')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'zalo' && this.socialPlatformVisibility['zalo'] }"
                      class='btn mx-auto social-share-btn-icon'>
                <svg class="zalo-icon" [ngClass]="{ svg_disable : !this.socialPlatformVisibility['zalo'] }"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 116.57" xml:space="preserve">
                  <g>
                    <title>Zalo</title>
                    <path class="st1" d="M38.66,113.29c-6.87,0-13.76,0.23-20.63-0.03C9.91,112.94,3.7,106.13,3.7,98c0-26.24,0.05-52.47,0-78.73 c0-8.98,7.02-15.24,15.09-15.47c8.45-0.23,16.93-0.05,25.41-0.05c0.15,0,0.35-0.08,0.43,0.18c-0.05,0.45-0.5,0.5-0.78,0.68 c-4.98,2.92-9.53,6.41-13.36,10.74c-6.31,7.14-10.69,15.34-12.17,24.88c-2.62,16.83,2.64,31.12,14.54,43.04 c2.11,2.14,2.39,3.8,0.7,6.67c-2.04,3.45-5.13,5.79-8.43,7.92c-0.35,0.2-0.7,0.45-1.06,0.68c-0.53,0.45-0.2,0.68,0.25,0.88 c0.1,0.23,0.23,0.43,0.38,0.63c2.89,2.57,5.63,5.31,8.48,7.92c1.33,1.23,2.67,2.51,3.95,3.8 C37.66,112.24,38.54,112.39,38.66,113.29L38.66,113.29L38.66,113.29z"/>
                    <path class="st0" d="M38.66,113.29c-0.13-0.88-1.01-1.03-1.53-1.56c-1.28-1.31-2.62-2.57-3.95-3.8c-2.84-2.62-5.58-5.36-8.48-7.92 c-0.15-0.2-0.28-0.4-0.38-0.63c6.41,1.26,12.68,0.4,18.84-1.48c2.09-0.63,4.18-1.26,6.29-1.79c1.43-0.38,2.94-0.3,4.33,0.2 c15.95,5.48,31.69,4.98,47.19-1.81c6.31-2.79,12.07-6.72,16.95-11.62c0.25-0.25,0.43-0.63,0.88-0.65c0.23,0.35,0.1,0.73,0.1,1.11 v14.71c0.05,8.4-6.69,15.24-15.09,15.32h-0.13c-9.05,0.05-18.11,0-27.17,0H40.17C39.67,113.32,39.17,113.29,38.66,113.29 L38.66,113.29L38.66,113.29z"/>
                    <path class="st1" d="M42.59,57c3.8,0,7.37-0.02,10.92,0c1.99,0.03,3.07,0.86,3.27,2.44c0.23,1.99-0.93,3.32-3.09,3.35 c-4.08,0.05-8.13,0.03-12.2,0.03c-1.18,0-2.34,0.05-3.52-0.03c-1.46-0.08-2.89-0.38-3.6-1.89c-0.7-1.51-0.2-2.87,0.75-4.1 c3.87-4.93,7.77-9.89,11.67-14.82c0.23-0.3,0.45-0.6,0.68-0.88c-0.25-0.43-0.6-0.23-0.91-0.25c-2.72-0.03-5.46,0-8.18-0.03 c-0.63,0-1.26-0.08-1.86-0.2c-1.43-0.33-2.31-1.76-1.99-3.17c0.23-0.96,0.98-1.74,1.94-1.96c0.6-0.15,1.23-0.23,1.86-0.23 c4.48-0.02,8.98-0.02,13.46,0c0.8-0.02,1.58,0.08,2.36,0.28c1.71,0.58,2.44,2.16,1.76,3.82c-0.6,1.43-1.56,2.67-2.52,3.9 c-3.3,4.2-6.59,8.38-9.89,12.53C43.24,56.12,42.99,56.45,42.59,57L42.59,57L42.59,57z"/>
                    <path class="st1" d="M71.77,43.77c0.6-0.78,1.23-1.51,2.26-1.71c1.99-0.4,3.85,0.88,3.87,2.89c0.08,5.03,0.05,10.06,0,15.09 c0,1.31-0.85,2.46-2.09,2.84c-1.26,0.48-2.69,0.1-3.52-0.98c-0.43-0.53-0.6-0.63-1.21-0.15c-2.29,1.86-4.88,2.19-7.67,1.28 c-4.48-1.46-6.31-4.96-6.82-9.21c-0.53-4.6,1.01-8.53,5.13-10.94C65.15,40.85,68.62,41.03,71.77,43.77L71.77,43.77L71.77,43.77z M62.86,52.95c0.05,1.11,0.4,2.16,1.06,3.04c1.36,1.81,3.95,2.19,5.79,0.83c0.3-0.23,0.58-0.5,0.83-0.83 c1.41-1.91,1.41-5.06,0-6.97c-0.71-0.98-1.81-1.56-2.99-1.58C64.77,47.27,62.84,49.4,62.86,52.95L62.86,52.95L62.86,52.95z M89.2,53.1c-0.2-6.46,4.05-11.29,10.09-11.47c6.41-0.2,11.09,4.1,11.29,10.39c0.2,6.36-3.7,10.87-9.71,11.47 C94.3,64.14,89.1,59.39,89.2,53.1L89.2,53.1L89.2,53.1z M95.51,52.5c-0.05,1.26,0.33,2.49,1.08,3.52c1.38,1.81,3.97,2.16,5.79,0.75 c0.28-0.2,0.5-0.45,0.73-0.7c1.46-1.91,1.46-5.13,0.03-7.04c-0.71-0.96-1.81-1.56-2.99-1.58C97.42,47.29,95.51,49.35,95.51,52.5 L95.51,52.5L95.51,52.5z M86.98,48.1c0,3.9,0.03,7.8,0,11.7c0.03,1.79-1.38,3.27-3.17,3.32c-0.3,0-0.63-0.03-0.93-0.1 c-1.26-0.33-2.21-1.66-2.21-3.25v-20c0-1.18-0.03-2.34,0-3.52c0.03-1.94,1.26-3.19,3.12-3.19c1.91-0.02,3.19,1.23,3.19,3.24 C87.01,40.22,86.98,44.17,86.98,48.1L86.98,48.1L86.98,48.1z"/>
                    <path class="st1" d="M20.18,0h82.53c5.57,0,10.61,2.26,14.27,5.91c3.65,3.65,5.91,8.7,5.91,14.27v76.22 c0,5.57-2.26,10.62-5.91,14.27c-3.65,3.65-8.7,5.91-14.27,5.91H20.18c-5.57,0-10.61-2.26-14.27-5.91C2.26,107.01,0,101.96,0,96.39 V20.17C0,14.6,2.26,9.56,5.91,5.91C9.56,2.26,14.6,0,20.18,0L20.18,0z M102.71,7.65H20.18c-3.46,0-6.59,1.4-8.86,3.67 c-2.27,2.27-3.67,5.4-3.67,8.86v76.22c0,3.46,1.4,6.59,3.67,8.86c2.27,2.27,5.4,3.67,8.86,3.67h82.53c3.46,0,6.59-1.4,8.86-3.67 c2.27-2.27,3.67-5.4,3.67-8.86V20.17c0-3.46-1.4-6.59-3.67-8.86C109.29,9.05,106.16,7.65,102.71,7.65L102.71,7.65z"/>
                  </g>
                </svg>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[11])"
                [position]="{ x: positions[11].x, y: positions[11].y }"
              >
              <button (click)="changeSocialMediaPreview('line')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'line' && this.socialPlatformVisibility['line'] }"
                      class='btn mx-auto social-share-btn-icon'>
                <svg class="line-icon" [ngClass]="{ svg_disable : !this.socialPlatformVisibility['line'] }" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 122.88" xml:space="preserve">
                  <g>
                    <title>Line</title>
                    <path class="st1" d="M96.26,122.88H26.62C11.92,122.88,0,110.96,0,96.26V26.62C0,11.92,11.92,0,26.62,0h69.63 c14.71,0,26.62,11.92,26.62,26.62v69.63C122.88,110.96,110.96,122.88,96.26,122.88L96.26,122.88z"/>
                    <path class="st0" d="M105.98,56.04c0-20.02-20.07-36.31-44.74-36.31c-24.67,0-44.74,16.29-44.74,36.31 c0,17.95,15.92,32.98,37.42,35.82c1.46,0.31,3.44,0.96,3.94,2.21c0.45,1.13,0.29,2.9,0.14,4.04c0,0-0.52,3.16-0.64,3.83 c-0.2,1.13-0.9,4.42,3.88,2.41c4.77-2.01,25.77-15.17,35.15-25.97h0C102.87,71.26,105.98,64.05,105.98,56.04L105.98,56.04z"/>
                    <path class="st1" d="M52.15,46.36h-3.14c-0.48,0-0.87,0.39-0.87,0.87v19.49c0,0.48,0.39,0.87,0.87,0.87h3.14 c0.48,0,0.87-0.39,0.87-0.87V47.23C53.02,46.75,52.63,46.36,52.15,46.36L52.15,46.36z"/>
                    <path class="st1" d="M73.75,46.36h-3.14c-0.48,0-0.87,0.39-0.87,0.87v11.58l-8.93-12.06c-0.02-0.03-0.04-0.06-0.07-0.09 c0,0,0,0,0-0.01c-0.02-0.02-0.04-0.04-0.05-0.05c-0.01,0-0.01-0.01-0.02-0.02c-0.02-0.01-0.03-0.03-0.05-0.04 c-0.01-0.01-0.01-0.01-0.02-0.02c-0.01-0.01-0.03-0.02-0.04-0.03c-0.01-0.01-0.02-0.01-0.03-0.02c-0.02-0.01-0.03-0.02-0.05-0.03 c-0.01,0-0.02-0.01-0.03-0.01c-0.02-0.01-0.03-0.02-0.05-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.02-0.01-0.03-0.01-0.05-0.02 c-0.01,0-0.02,0-0.03-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.01,0-0.02,0-0.04-0.01c-0.02,0-0.03,0-0.05-0.01c-0.02,0-0.03,0-0.04,0 c-0.01,0-0.02,0-0.03,0h-3.14c-0.48,0-0.87,0.39-0.87,0.87v19.49c0,0.48,0.39,0.87,0.87,0.87h3.14c0.48,0,0.87-0.39,0.87-0.87 V55.15l8.94,12.08c0.06,0.09,0.14,0.16,0.22,0.21c0,0,0.01,0,0.01,0.01c0.02,0.01,0.04,0.02,0.05,0.03c0.01,0,0.02,0.01,0.03,0.01 c0.01,0.01,0.03,0.01,0.04,0.02s0.03,0.01,0.04,0.02c0.01,0,0.02,0.01,0.03,0.01c0.02,0.01,0.04,0.01,0.06,0.02c0,0,0.01,0,0.01,0 c0.07,0.02,0.15,0.03,0.22,0.03h3.14c0.48,0,0.87-0.39,0.87-0.87V47.23C74.62,46.75,74.23,46.36,73.75,46.36L73.75,46.36z"/>
                    <path class="st1" d="M44.59,62.71h-8.53V47.23c0-0.48-0.39-0.87-0.87-0.87h-3.14c-0.48,0-0.87,0.39-0.87,0.87v19.49 c0,0.23,0.09,0.45,0.24,0.6c0,0,0.01,0.01,0.01,0.01c0,0,0.01,0.01,0.01,0.01c0.16,0.15,0.37,0.24,0.6,0.24h12.54 c0.48,0,0.87-0.39,0.87-0.87v-3.14C45.46,63.11,45.07,62.71,44.59,62.71L44.59,62.71z"/>
                    <path class="st1" d="M91.08,51.24c0.48,0,0.87-0.39,0.87-0.87v-3.14c0-0.48-0.39-0.87-0.87-0.87H78.54c-0.23,0-0.45,0.09-0.6,0.25 c0,0-0.01,0.01-0.01,0.01c0,0.01-0.01,0.01-0.01,0.02c-0.15,0.16-0.24,0.37-0.24,0.6v19.49c0,0.23,0.09,0.45,0.24,0.6 c0,0,0.01,0.01,0.01,0.01c0,0,0.01,0.01,0.01,0.01c0.16,0.15,0.37,0.25,0.6,0.25h12.54c0.48,0,0.87-0.39,0.87-0.87v-3.14 c0-0.48-0.39-0.87-0.87-0.87h-8.53v-3.29h8.53c0.48,0,0.87-0.39,0.87-0.87v-3.14c0-0.48-0.39-0.87-0.87-0.87h-8.53v-3.29H91.08 L91.08,51.24z"/>
                  </g>
                </svg>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[12])"
                [position]="{ x: positions[12].x, y: positions[12].y }"
              >
              <button (click)="changeSocialMediaPreview('wechat')"
                      [ngClass]="{active: currentSocialMediaPreviewing == 'wechat' && this.socialPlatformVisibility['wechat'] }"
                      class='btn mx-auto social-share-btn-icon'>
                <svg class="wechat-icon" [ngClass]="{ svg_disable : !this.socialPlatformVisibility['wechat'] }" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24.5 22.88" xml:space="preserve">
                  <g>
                    <title>WeChat</title>
                    <path class="st1" d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z"/>
                    <path class="st0" d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z"/>
                  </g>
                </svg>
              </button>
              </div>
            </div>
            <div class='d-flex justify-content-center'>
              <div
                class="box-swap"
                ngDraggable
                [preventDefaultEvent]="true"
                [zIndexMoving]="'9999'"
                [trackPosition]="false"
                (movingOffset)="onMoving($event, positions[13])"
                [position]="{ x: positions[13].x, y: positions[13].y }"
              >
                <button (click)="changeSocialMediaPreview('bluesky')"
                        [ngClass]="{active: currentSocialMediaPreviewing == 'bluesky' && this.socialPlatformVisibility['bluesky'],
                        disable: !this.socialPlatformVisibility['bluesky'] }"
                        class='btn mx-auto social-share-btn-icon'>
                  <svg class="bluesky-icon" [ngClass]="{ svg_disable : !this.socialPlatformVisibility['bluesky'] }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <title>Bluesky</title>
                    <path d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>

        </div>
        <div class='accordion' id='contentSectionAccordion'>
          <div class='card card-box'>
            <div class='card-header p-0 pl-3 header-color' id='headingContent'>
              <small>{{currentSocialMediaPreviewing == 'email' ? 'Email Invite' : 'Social Post'}}</small>
              <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                      data-target='#contentSectionContainer' aria-expanded='true' aria-controls='collapseOne'
                      (click)="sectionsStatus['content'] = !sectionsStatus['content']">
                <i class='fa fa-{{ !sectionsStatus["content"] ? "plus" : "minus" }}'></i>
              </button>
            </div>
            <div class='pt-3 collapse show' id='contentSectionContainer' aria-labelledby='headingContent'
                 data-parent='#contentSectionAccordion'>
              <div class='form-group'>
                <div class='row'>
                  <div class='col-md-11 mb-3 mx-auto'>
                    <div class='position-relative'>
                      <input class="mr-2" placeholder=""
                             [checked]="this.socialPlatformVisibility[currentSocialMediaPreviewing]"
                             (change)="changeCheckBox($event)"
                             type="checkbox">
                      <label style="margin-right: 4px;">Active</label>
                      <button class="btn btn-default btn-sm d-block"
                              [ngClass]="(socialShareInfo && !socialShareSkip ) && 'social-share-btn'"
                              [disabled]="!this.socialPlatformVisibility[currentSocialMediaPreviewing]"
                              (click)="applyToOthers();onSkip()">Use in all social channels
                      </button>
                      <div class='social-share-tooltip'
                           [ngStyle]="socialShareInfo ? {'display' : 'block'} : {'display' : 'none'}"
                           *ngIf="!socialShareSkip">
                        Please click here to use the same social message for all the other social networks
                        <button type="button" class="btn btn-default btn-sm" (click)="onSkip()">Skip</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='row'
                     *ngIf="currentSocialMediaPreviewing == 'email'">
                  <div class='col-md-11 mb-3 mx-auto'>
                    <div class="d-flex align-items-center">
                      <small class="text-capitalize">Subject Line</small>
                      <app-variables (onVariableForClick)="onAddVariablesButtonClick($event)"
                                     [variableClickFor]="'title'" [variables]="variables"
                                     (onVariableClick)="onVariableClick($event, title)"></app-variables>
                    </div>
                    <div class='input-group input-group-sm variable-input'>
                      <!--                      <input class="form-control" type='text' #title-->
                      <!--                             [(ngModel)]="socialMedias[currentSocialMediaPreviewing]['title']"/>-->
                      <textarea id="test-id" class="form-control overflow-hidden auto-grow-textarea" #title
                                [disabled]="!this.socialPlatformVisibility[currentSocialMediaPreviewing]"
                                [(ngModel)]="socialMedias[currentSocialMediaPreviewing]['title']"
                                type="text" (input)="autoGrow(title);"
                                [rows]="socialMedias[currentSocialMediaPreviewing]['title'] ? 2 : 1"></textarea>
                      <!--                      <app-file-browser [clientId]="campaign?.client_id" [projectId]="campaign?.project_id"></app-file-browser>-->
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-11 mb-3 mx-auto'>
                    <div class="d-flex align-items-center">
                      <small class="text-capitalize">{{getPreText()}} <span
                        *ngIf="currentSocialMediaPreviewing === 'twitter'">
                        ({{(socialMedias[currentSocialMediaPreviewing]['message']).length || 0}}
                        /280)</span></small>
                      <app-variables (onVariableForClick)="onAddVariablesButtonClick($event)"
                                     [variableClickFor]="'message'" [variables]="variables"
                                     (onVariableClick)="onVariableClick($event, message)"></app-variables>
                      <button type="button" class="info-button pt-1" [ngbPopover]="popInfoTemplate" data-html="true"
                              triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                              [popoverClass]="'info-popover'" (click)="applyRTLEmbedding(message, 'social', currentSocialMediaPreviewing)">
                        <i class="fa fa-paragraph"></i>
                      </button>
                      <ng-template #popInfoTemplate>
                        <p class="mb-0">
                          Move the text cursor before the English word inside the RTL text and click the button.
                          This would make the RTL texts to show properly when they're mixed with LTR characters.
                        </p>
                      </ng-template>
                    </div>
                    <div class='input-group input-group-sm variable-textarea'>
                      <textarea class="form-control overflow-hidden auto-grow-textarea" type='text' #message
                                (keyup)="validateSocialMessage()" (input)="autoGrow(message)"
                                maxlength="{{currentSocialMediaPreviewing === 'twitter' ? 280 : -1}}"
                                [disabled]="!this.socialPlatformVisibility[currentSocialMediaPreviewing]"
                                rows="5" [(ngModel)]="socialMedias[currentSocialMediaPreviewing]['message']"></textarea>
                      <!--                      <app-file-browser [clientId]="campaign?.client_id" [projectId]="campaign?.project_id"></app-file-browser>-->
                    </div>
                    <div class="bar error-brd" *ngIf="socialMessageError[currentSocialMediaPreviewing]">
                      <div class="invalid-text">Variable #InfluencerCampaignPageUrl# must be included in the message
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion" id="widgetSectionAccordion" *ngIf="currentSocialMediaPreviewing !== 'email'">
          <div class="card card-box">
            <div class='card-header p-0 pl-3 header-color' id='headingWidget'>
              <small>Widget Settings</small>
              <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                      data-target='#widgetSectionContainer' aria-expanded='true' aria-controls='collapseOne'
                      (click)="sectionsStatus['widget'] = !sectionsStatus['widget']">
                <i class='fa fa-{{ !sectionsStatus["widget"] ? "plus" : "minus" }}'></i>
              </button>
            </div>
            <div class="pt-3 collapse show" id="widgetSectionContainer" aria-labelledby="headingWidget"
                 data-parent="#widgetSectionAccordion">
              <div class="form-group">
                <div class="row">
                  <div class='col-md-11 mx-auto'>
                    <div class='position-relative'>
                      <div style="margin-bottom: 8px">
                        <input id="influencer_update_image" placeholder="" type="checkbox"
                               [(ngModel)]="campaignMeta['allow_influencers_to_update_their_picture_in_widget']"
                               style="display: inline; margin-right: 5px;"
                               [disabled]="!widget_Settings?.allow_influencers_to_update_their_picture_in_widget"
                        >
                        <label style="display: inline"
                               [ngClass]="{picture_disable : !widget_Settings?.allow_influencers_to_update_their_picture_in_widget}">Allow
                          influencers to update their picture through the widget{{!widget_Settings?.allow_influencers_to_update_their_picture_in_widget?" (Enable on Widget Settings page first)":""}}</label>
                      </div>
                      <div class='mb-3 mx-auto'>
                        <input id="allow_influencers_to_use_ai_avatar_in_widget" placeholder="" type="checkbox" value="true"
                               style="display: inline; margin-right: 5px;"
                               [(ngModel)]="campaignMeta['allow_influencers_to_use_ai_avatar_in_widget']"
                               [disabled]="!campaignMeta['allow_influencers_to_update_their_picture_in_widget']"
                        >
                        <label for="allow_influencers_to_use_ai_avatar_in_widget" style="display: inline">
                          Allow influencers to use AI to generate their Picture/Avatar
                        </label>
                        <div *ngIf="campaignMeta['allow_influencers_to_use_ai_avatar_in_widget']"  class="mb-2">
                          AI Avatar Template
                          <ul class="avatar-template-menu-items p-0">
                            <li class="dropdown avatar-template-dropdown d-block text-decoration-none"
                                [ngClass]="{'disable-dropdown': !campaignMeta['allow_influencers_to_use_ai_avatar_in_widget'] || widgetAIAvatarTemplates.length <= 0}">
                              <div class="dropdown-toggle d-flex align-items-center p-3" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                                <div class="d-flex align-items-center w-100" *ngIf="campaignMeta['widget_ai_avatar_template'] && selectedWidgetAIAvatarTemplate; else noWidgetAITemplate">
                                  <div class="avatar-image mr-2">
                                    <img class="h-100 img-fluid" [src]="selectedWidgetAIAvatarTemplate?.selectedImageVariation?.url" />
                                  </div>
                                  <span class="avatar-text text-wrap">{{selectedWidgetAIAvatarTemplate?.name}}</span>
                                </div>
                                <ng-template #noWidgetAITemplate>
                                  <span>Select Widget AI Avatar Template</span>
                                </ng-template>
                              </div>
                              <div class="dropdown-menu avatar-template-list-dropdown w-100">
                                <div class="avatar-template-list-option">
                                  <div *ngFor="let template of widgetAIAvatarTemplates" class="dropdown-item px-3">
                                    <div class="image-wrapper d-flex align-items-center pointer" (click)="selectWidgetAIAvatarTemplate(template)">
                                      <div class="avatar-image mr-2">
                                        <img class="h-100 img-fluid" [src]="template?.selectedImageVariation?.url" alt="{{template?.name}}" title="{{template?.name}}" />
                                      </div>
                                      <span class="avatar-text text-wrap">{{template?.name}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <button class="btn customize-btn" type="button" role="button" (click)="showCustomizeAvatarTemplatePopup()">
                            <span class="btn-wrapper--icon"><i class="fa fa-picture-o" aria-hidden="true"></i></span>
                            <span class="btn-wrapper--label">Customize</span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <input style="display: inline; margin-right: 5px;" id="campaign_registration_widget"
                               placeholder="" type="checkbox"
                               [(ngModel)]="campaignMeta['allow_campaign_to_launch_when_registration_widget_is_disabled']">
                        <label style="display: inline">Allow campaign to launch when registration widget is not
                          active</label>
                      </div>
                    </div>
                    <div>
                        <input style="display: inline; margin-right: 5px;" id="switch_widget_launched"
                               placeholder="" type="checkbox"
                               [(ngModel)]="campaignMeta['switch_widget_to_this_campaign_when_launched']">
                        <label style="display: inline">Switch widget to this campaign when the campaign launch starts</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion" id="campaignSectionAccordion" *ngIf="currentSocialMediaPreviewing !== 'email'">
          <div class="card card-box">
            <div class='card-header p-0 pl-3 header-color' id='headingCampaign'>
              <small>Meta</small>
              <button class='btn btn-link ml-auto' type='button' data-toggle='collapse'
                      data-target='#campaignSectionContainer' aria-expanded='true' aria-controls='collapseOne'
                      (click)="sectionsStatus['campaign'] = !sectionsStatus['campaign']">
                <i class='fa fa-{{ !sectionsStatus["campaign"] ? "plus" : "minus" }}'></i>
              </button>
            </div>
            <div class="pt-3 collapse show" id="campaignSectionContainer" aria-labelledby="headingCampaign"
                 data-parent="#campaignSectionAccordion">
              <div class="form-group">
                <div class='row d-none'>
                  <div class='col-md-11 mb-3 mx-auto'>
                    <button class="btn btn-default btn-sm" (click)="applyToOthers()">Apply to the others</button>
                  </div>
                </div>
                <div class="row">
                  <div class='col-md-11 mb-3 mx-auto'>
                    <div class="d-flex align-items-center">
                      <small class="text-capitalize">Title</small>
                      <app-variables (onVariableForClick)="onMetaVariablesButtonClick($event)"
                                     [variableClickFor]="'title'" [variables]="variables"
                                     (onVariableClick)="onVariableClick($event, metaTitle)">
                      </app-variables>
                      <button type="button" class="info-button pt-1" [ngbPopover]="popInfoTemplate" data-html="true"
                              triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                              [popoverClass]="'info-popover'" (click)="applyRTLEmbedding(metaTitle, 'title')">
                        <i class="fa fa-paragraph"></i>
                      </button>
                      <ng-template #popInfoTemplate>
                        <p class="mb-0">
                          Move the text cursor before the English word inside the RTL text and click the button.
                          This would make the RTL texts to show properly when they're mixed with LTR characters.
                        </p>
                      </ng-template>
                    </div>
                    <div class='input-group input-group-sm variable-input'>
                      <input class="form-control" type='text' #metaTitle
                             [value]="campaignMeta.title"
                             (keyup)="updateValue($event, 'title')"/>
                      <!--                      <app-file-browser [clientId]="campaign?.client_id" [projectId]="campaign?.project_id"></app-file-browser>-->
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-11 mb-3 mx-auto'>
                    <div class="d-flex align-items-center">
                      <small>Description
                        ({{(campaignMeta.description).length || 0}}
                        /160)</small>
                      <app-variables (onVariableForClick)="onMetaVariablesButtonClick($event)"
                                     [variableClickFor]="'description'" [variables]="variables"
                                     (onVariableClick)="onVariableClick($event, description)">
                      </app-variables>
                      <button type="button" class="info-button pt-1" [ngbPopover]="popInfoTemplate" data-html="true"
                              triggers="mouseenter:mouseleave" placement="auto" [container]="'body'"
                              [popoverClass]="'info-popover'" (click)="applyRTLEmbedding(description, 'description')">
                        <i class="fa fa-paragraph"></i>
                      </button>
                      <ng-template #popInfoTemplate>
                        <p class="mb-0">
                          Move the text cursor before the English word inside the RTL text and click the button.
                          This would make the RTL texts to show properly when they're mixed with LTR characters.
                        </p>
                      </ng-template>
                    </div>
                    <div class='input-group input-group-sm variable-textarea'>
                      <textarea class="form-control overflow-hidden auto-grow-textarea" #description
                                (keyup)="updateValue($event, 'description')"
                                [value]="campaignMeta.description"
                                (input)="autoGrow(description)"
                                maxlength="160"
                                rows="5"></textarea>
                      <!--                      <app-file-browser [clientId]="campaign?.client_id" [projectId]="campaign?.project_id"></app-file-browser>-->
                    </div>
                  </div>
                </div>
                <div class='row'>
                  <div class='col-md-11 mb-3 mx-auto'>
                    <!--                    <label><small>Image/Video (Suggested format: 1200x720px)</small></label>-->
                    <label><small>Image(Suggested format: 1200x720px)</small></label>
                    <div>
                      <ng-container *ngIf="getImage()">
                        <img *ngIf="contentType === 'image'"
                             class="img-fluid rounded mr-3 shadow-sm"
                             src="{{getImage()}}" width="100%"
                             height="100"/>
                        <video *ngIf="contentType === 'video'"
                               class="img-fluid rounded mr-3 shadow-sm"
                               src="{{getImage()}}" width="100%" controls
                               height="100"></video>
                      </ng-container>
                      <div class="custom-file w-100 mt-3">
                        <div class="d-flex">
                          <button (click)="personalizeImage()" class="btn btn-default btn-sm" type="button">
                            Personalize Image
                          </button>
                          <button type="button" class="btn btn-default btn-sm" placement="auto"
                                  popoverClass="custom-popover"
                                  [autoClose]="'outside'" #popupTemp="ngbPopover" [ngbPopover]="popTemplate"
                                  container="body">
                            <!--Upload image or video-->
                            Upload Image
                          </button>
                          <ng-template #popTemplate>
                            <div class='d-flex bg-light justify-content-around'>
                              <div class='col-md-3 d-flex justify-content-center'>
                                <button class='btn mx-auto' title="Upload Image"
                                        (click)="uploadTab = 'image'"
                                        style='outline: none; box-shadow: none;'>
                                  <i class='fa fa-image'></i>
                                </button>
                              </div>
                              <!--
                              <div class='col-md-3 d-flex justify-content-center' style="display: none">
                                <button class='btn mx-auto' title="Upload Video"
                                        (click)="uploadTab = 'video'"
                                        style='outline: none; box-shadow: none;'>
                                  <i class='fa fa-video-camera'></i>
                                </button>
                              </div>
                              -->
                              <!--                              <div class='col-md-3 d-flex justify-content-center'>-->
                              <!--                                <button title="Video By Url"-->
                              <!--                                        (click)="uploadTab = 'url'"-->
                              <!--                                        class='btn mx-auto' style='outline: none; box-shadow: none;'>-->
                              <!--                                  <i class='fa fa-link'></i>-->
                              <!--                                </button>-->
                              <!--                              </div>-->
                            </div>
                            <div class="p-2">
                              <div *ngIf="uploadTab === 'image'" class="draggable" draggable="true">
                                <strong>Drop image</strong>
                                <br/>
                                (Or click)
                                <div class="file-input-div">
                                  <input type="file" accept="image/jpeg, image/jpg, image/png, image/gif, image/webp"
                                         (change)="onSocialMediaImageSelect($event);popupTemp.close();"
                                         tabindex="-1" role="button" dir="auto">
                                </div>
                              </div>
                              <div *ngIf="uploadTab === 'video'" class="draggable" draggable="true">
                                <strong>Drop video</strong>
                                <br/>
                                (Or click)
                                <div class="file-input-div">
                                  <input type="file" accept="video/mp4"
                                         (change)="onSocialMediaImageSelect($event);popupTemp.close();"
                                         tabindex="-1" role="button" dir="auto">
                                </div>
                              </div>
                              <!--                              <div *ngIf="uploadTab === 'url'" class="p-3">-->
                              <!--                                <div style="margin: 5px;">format: mp4, max-size: 50mb, max-resolution: 1920x1200, aspect-ratio: 1:2.39 and 2.39:1</div>-->
                              <!--                                <app-variables (onVariableForClick)="onMetaVariablesButtonClick($event)"-->
                              <!--                                               [variableClickFor]="'video'" [variables]="variables"-->
                              <!--                                               (onVariableClick)="onVariableClick($event, 'video')">-->
                              <!--                                </app-variables>-->
                              <!--                                <input type="text" placeholder="Video URL" dir="auto" [(ngModel)]="videoURL" class="form-control"-->
                              <!--                                       [value]="campaignMeta.video"-->
                              <!--                                       (keyup)="updateValue($event, 'video')">-->
                              <!--                                <input type="text" placeholder="Video Width" dir="auto" [(ngModel)]="videoWidth" class="form-control"-->
                              <!--                                       [value]="campaignMeta.video_width"-->
                              <!--                                       (keyup)="updateValue($event, 'video_width')">-->
                              <!--                                <input type="text" placeholder="Video Height" dir="auto" [(ngModel)]="videoHeight" class="form-control"-->
                              <!--                                       [value]="campaignMeta.video_height"-->
                              <!--                                       (keyup)="updateValue($event, 'video_height')">-->
                              <!--                                <button title="Add Video" (click)="addVideoUrl();popupTemp.close()" [disabled]="!videoURL"-->
                              <!--                                        class='btn btn-default btn-sm float-right my-3' style='outline: none; box-shadow: none;'>-->
                              <!--                                  Insert-->
                              <!--                                </button>-->
                              <!--                              </div>-->
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div style="margin-left: 19rem !important;">
  <div class="container-fluid pt-3 mobile-p0 campaign-creation-steps">
    <div class='rightBody'>
      <app-status-bar [loading]="loader$ | async" [step]="step"
                      [skipLanding]="campaign?.landing_page_status === 'skipped'"
                      (SaveButtonClick)="onSaveButtonClick()">
      </app-status-bar>
      <div class="card card-box mb-8rem">
        <div class="card-header py-3">
          <div *ngIf="currentSocialMediaPreviewing == 'email'"
               class="card-header--title font-size-lg d-flex align-items-baseline">
            Preview <span class="social-name"> {{currentSocialMediaPreviewing}} </span>
          </div>
          <div *ngIf="currentSocialMediaPreviewing != 'email'"
               class="card-header--title font-size-lg d-flex align-items-baseline">
            Preview on <span
            class="social-name"> {{getCurrentSocialMediaPreviewing()}} </span>
          </div>
          <div class="card-header--actions pull-right">
          </div>
        </div>
        <div class="card-body p-0">
          <ng-container
            *ngIf="currentSocialMediaPreviewing == 'facebook'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'Facebook'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'twitter'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'Twitter'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'linkedin'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'LinkedIn'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'whatsapp'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'Whatsapp'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'slack'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'Slack'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'instagram'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'instagram'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'email'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'Email'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'facebook_messenger'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'facebook_messenger'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'microsoft_teams'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'microsoft_teams'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'sms'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'sms'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'zalo'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'zalo'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'line'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'line'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'wechat'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'wechat'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
          <ng-container *ngIf="currentSocialMediaPreviewing == 'bluesky'">
            <div class="card p-3 bg-heavy-rain email-body h-100" style="border-radius: 0">
              <app-social-share-preview [socialMedia]="'bluesky'"
                                        [title]="getTitle()"
                                        [image]="getImage()"
                                        [contentType]="contentType"
                                        [message]="getMessage()"
                                        [description]="getDescription() || 'Your description here...'"
                                        [url]="campaign ? campaign.project.subdomain : 'my-event.snoball.events'">
              </app-social-share-preview>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="operation-box">
  <div class="col-md-12">
    <div class="row d-flex align-items-center">
      <div class="col-md-2">
        <div class="text-left">
          <button (click)="onBackButonClick()" class="btn btn-default">
            Back
          </button>
        </div>
      </div>
      <div class="col-md-7 d-flex justify-content-center social-networks-icon">
        <button class="btn" (click)="currentSocialMediaPreviewing = 'facebook'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'facebook' && this.socialPlatformVisibility['facebook'],
                    disable: !this.socialPlatformVisibility['facebook'] }">
          <i class='fa fa-facebook social-button' title="Facebook"></i>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'twitter'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'twitter' && this.socialPlatformVisibility['twitter'],
                    disable: !this.socialPlatformVisibility['twitter'] }">
          <i class='fa-brands fa-x-twitter social-button' title="Twitter"></i>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'linkedin'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'linkedin' && this.socialPlatformVisibility['linkedin'],
                    disable: !this.socialPlatformVisibility['linkedin'] }">
          <i class='fa fa-linkedin social-button' title="Linkedin"></i>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'whatsapp'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'whatsapp' && this.socialPlatformVisibility['whatsapp'],
                    disable: !this.socialPlatformVisibility['whatsapp'] }">
          <i class='fa fa-whatsapp social-button' title="Whatsapp"></i>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'slack'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'slack' && this.socialPlatformVisibility['slack'],
                    disable: !this.socialPlatformVisibility['slack'] }">
          <i class='fa fa-slack social-button' title="Slack"></i>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'instagram'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'instagram' && this.socialPlatformVisibility['instagram'],
                    disable: !this.socialPlatformVisibility['instagram'] }">
          <i class='fa fa-instagram social-button' title="Instagram"></i>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'email'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'email' && this.socialPlatformVisibility['email'],
                    disable: !this.socialPlatformVisibility['email'] }">
          <i class='fa fa-envelope social-button' title="Email"></i>
        </button>
        <button class="btn social-share-btn-icon large-icon social-button" (click)="currentSocialMediaPreviewing = 'facebook_messenger'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'facebook_messenger' && this.socialPlatformVisibility['facebook_messenger'],
                    disable: !this.socialPlatformVisibility['facebook_messenger'] }">
          <svg [ngClass]="{ svg_disable : !this.socialPlatformVisibility['facebook_messenger'] }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>Facebook messenger</title>
            <path d="M256.55 8C116.52 8 8 110.34 8 248.57c0 72.3 29.71 134.78 78.07 177.94 8.35 7.51 6.63 11.86 8.05 58.23A19.92 19.92 0 0 0 122 502.31c52.91-23.3 53.59-25.14 62.56-22.7C337.85 521.8 504 423.7 504 248.57 504 110.34 396.59 8 256.55 8zm149.24 185.13l-73 115.57a37.37 37.37 0 0 1-53.91 9.93l-58.08-43.47a15 15 0 0 0-18 0l-78.37 59.44c-10.46 7.93-24.16-4.6-17.11-15.67l73-115.57a37.36 37.36 0 0 1 53.91-9.93l58.06 43.46a15 15 0 0 0 18 0l78.41-59.38c10.44-7.98 24.14 4.54 17.09 15.62z"/>
          </svg>
        </button>
        <button class="btn social-share-btn-icon large-icon" (click)="currentSocialMediaPreviewing = 'microsoft_teams'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'microsoft_teams' && this.socialPlatformVisibility['microsoft_teams'],
                    disable: !this.socialPlatformVisibility['microsoft_teams'] }">
          <svg [ngClass]="{ svg_disable : !this.socialPlatformVisibility['microsoft_teams'] }" class="team-icon" version="1.1" id="Livello_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2228.83 2073.33" xml:space="preserve">
            <title>Microsoft teams</title>
            <path class="st0" d="M1554.64,777.5h575.71c54.39,0,98.48,44.09,98.48,98.48l0,0v524.4c0,199.9-162.05,361.95-361.95,361.95l0,0	h-1.71c-199.9,0.03-361.97-162-362-361.9c0-0.02,0-0.03,0-0.05V828.97C1503.17,800.54,1526.21,777.5,1554.64,777.5L1554.64,777.5z"/>
            <circle class="st0" cx="1943.75" cy="440.58" r="233.25"/>
            <circle class="st1" cx="1218.08" cy="336.92" r="336.92"/>
            <path class="st1" d="M1667.32,777.5H717.01c-53.74,1.33-96.26,45.93-95.01,99.68v598.1c-7.51,322.52,247.66,590.16,570.17,598.05	c322.51-7.89,577.67-275.53,570.17-598.05v-598.1C1763.58,823.43,1721.07,778.83,1667.32,777.5z"/>
            <path class="st2" d="M1244,777.5v838.15c-0.26,38.44-23.55,72.96-59.09,87.6c-11.32,4.79-23.48,7.25-35.77,7.26H667.61	c-6.74-17.1-12.96-34.21-18.14-51.83c-18.14-59.48-27.4-121.31-27.47-183.49V877.02c-1.25-53.66,41.2-98.19,94.85-99.52H1244z"/>
            <path class="st3" d="M1192.17,777.5v889.98c0,12.29-2.47,24.45-7.26,35.77c-14.63,35.54-49.16,58.83-87.6,59.09H691.97	c-8.81-17.1-17.1-34.21-24.36-51.83s-12.96-34.21-18.14-51.83c-18.14-59.48-27.4-121.31-27.47-183.49V877.02	c-1.25-53.66,41.2-98.19,94.85-99.52H1192.17z"/>
            <path class="st3" d="M1192.17,777.5v786.31c-0.4,52.22-42.63,94.46-94.85,94.85H649.47c-18.14-59.48-27.4-121.31-27.47-183.49	V877.02c-1.25-53.66,41.2-98.19,94.85-99.52H1192.17z"/>
            <path class="st3" d="M1140.33,777.5v786.31c-0.4,52.22-42.63,94.46-94.85,94.85H649.47c-18.14-59.48-27.4-121.31-27.47-183.49	V877.02c-1.25-53.66,41.2-98.19,94.85-99.52H1140.33z"/>
            <path class="st2" d="M1244,509.52V672.8c-8.81,0.52-17.1,1.04-25.92,1.04s-17.1-0.52-25.92-1.04c-17.5-1.16-34.85-3.94-51.83-8.29	c-104.96-24.86-191.68-98.47-233.25-198c-7.15-16.71-12.71-34.07-16.59-51.83h258.65C1201.45,414.87,1243.8,457.22,1244,509.52z"/>
            <path class="st3" d="M1192.17,561.35V672.8c-17.5-1.16-34.85-3.94-51.83-8.29c-104.96-24.86-191.68-98.47-233.25-198h190.23	C1149.62,466.7,1191.97,509.05,1192.17,561.35z"/>
            <path class="st3" d="M1192.17,561.35V672.8c-17.5-1.16-34.85-3.94-51.83-8.29c-104.96-24.86-191.68-98.47-233.25-198h190.23	C1149.62,466.7,1191.97,509.05,1192.17,561.35z"/>
            <path class="st3" d="M1140.33,561.35V664.5c-104.96-24.86-191.68-98.47-233.25-198h138.4	C1097.78,466.7,1140.13,509.05,1140.33,561.35z"/>
            <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="198.0988" y1="-1123.0724" x2="942.2333" y2="165.7381" gradientTransform="matrix(1 0 0 1 0 1515.3333)">
              <stop offset="0" style="stop-color:#5A62C3"/>
              <stop offset="0.5" style="stop-color:#4D55BD"/>
              <stop offset="1" style="stop-color:#3940AB"/>
            </linearGradient>
            <path class="st4" d="M95.01,466.5h950.31c52.47,0,95.01,42.54,95.01,95.01v950.31c0,52.47-42.54,95.01-95.01,95.01H95.01	c-52.47,0-95.01-42.54-95.01-95.01V561.51C0,509.04,42.54,466.5,95.01,466.5z"/>
            <path class="st5" d="M820.21,828.19H630.24v517.3H509.21v-517.3H320.12V727.84h500.09V828.19z"/>
          </svg>
        </button>
        <button class="btn" (click)="currentSocialMediaPreviewing = 'sms'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'sms' && this.socialPlatformVisibility['sms'],
                    disable: !this.socialPlatformVisibility['sms'] }">
          <i class="fa fa-comments" title="SMS"></i>
        </button>
        <button class="btn social-share-btn-icon large-icon" (click)="currentSocialMediaPreviewing = 'zalo'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'zalo' && this.socialPlatformVisibility['zalo'],
                    disable: !this.socialPlatformVisibility['zalo'] }">
          <svg [ngClass]="{ svg_disable : !this.socialPlatformVisibility['zalo'] }" class="zalo-icon" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 116.57" xml:space="preserve">
            <g>
              <title>Zalo</title>
              <path class="st1" d="M38.66,113.29c-6.87,0-13.76,0.23-20.63-0.03C9.91,112.94,3.7,106.13,3.7,98c0-26.24,0.05-52.47,0-78.73 c0-8.98,7.02-15.24,15.09-15.47c8.45-0.23,16.93-0.05,25.41-0.05c0.15,0,0.35-0.08,0.43,0.18c-0.05,0.45-0.5,0.5-0.78,0.68 c-4.98,2.92-9.53,6.41-13.36,10.74c-6.31,7.14-10.69,15.34-12.17,24.88c-2.62,16.83,2.64,31.12,14.54,43.04 c2.11,2.14,2.39,3.8,0.7,6.67c-2.04,3.45-5.13,5.79-8.43,7.92c-0.35,0.2-0.7,0.45-1.06,0.68c-0.53,0.45-0.2,0.68,0.25,0.88 c0.1,0.23,0.23,0.43,0.38,0.63c2.89,2.57,5.63,5.31,8.48,7.92c1.33,1.23,2.67,2.51,3.95,3.8 C37.66,112.24,38.54,112.39,38.66,113.29L38.66,113.29L38.66,113.29z"/>
              <path class="st0" d="M38.66,113.29c-0.13-0.88-1.01-1.03-1.53-1.56c-1.28-1.31-2.62-2.57-3.95-3.8c-2.84-2.62-5.58-5.36-8.48-7.92 c-0.15-0.2-0.28-0.4-0.38-0.63c6.41,1.26,12.68,0.4,18.84-1.48c2.09-0.63,4.18-1.26,6.29-1.79c1.43-0.38,2.94-0.3,4.33,0.2 c15.95,5.48,31.69,4.98,47.19-1.81c6.31-2.79,12.07-6.72,16.95-11.62c0.25-0.25,0.43-0.63,0.88-0.65c0.23,0.35,0.1,0.73,0.1,1.11 v14.71c0.05,8.4-6.69,15.24-15.09,15.32h-0.13c-9.05,0.05-18.11,0-27.17,0H40.17C39.67,113.32,39.17,113.29,38.66,113.29 L38.66,113.29L38.66,113.29z"/>
              <path class="st1" d="M42.59,57c3.8,0,7.37-0.02,10.92,0c1.99,0.03,3.07,0.86,3.27,2.44c0.23,1.99-0.93,3.32-3.09,3.35 c-4.08,0.05-8.13,0.03-12.2,0.03c-1.18,0-2.34,0.05-3.52-0.03c-1.46-0.08-2.89-0.38-3.6-1.89c-0.7-1.51-0.2-2.87,0.75-4.1 c3.87-4.93,7.77-9.89,11.67-14.82c0.23-0.3,0.45-0.6,0.68-0.88c-0.25-0.43-0.6-0.23-0.91-0.25c-2.72-0.03-5.46,0-8.18-0.03 c-0.63,0-1.26-0.08-1.86-0.2c-1.43-0.33-2.31-1.76-1.99-3.17c0.23-0.96,0.98-1.74,1.94-1.96c0.6-0.15,1.23-0.23,1.86-0.23 c4.48-0.02,8.98-0.02,13.46,0c0.8-0.02,1.58,0.08,2.36,0.28c1.71,0.58,2.44,2.16,1.76,3.82c-0.6,1.43-1.56,2.67-2.52,3.9 c-3.3,4.2-6.59,8.38-9.89,12.53C43.24,56.12,42.99,56.45,42.59,57L42.59,57L42.59,57z"/>
              <path class="st1" d="M71.77,43.77c0.6-0.78,1.23-1.51,2.26-1.71c1.99-0.4,3.85,0.88,3.87,2.89c0.08,5.03,0.05,10.06,0,15.09 c0,1.31-0.85,2.46-2.09,2.84c-1.26,0.48-2.69,0.1-3.52-0.98c-0.43-0.53-0.6-0.63-1.21-0.15c-2.29,1.86-4.88,2.19-7.67,1.28 c-4.48-1.46-6.31-4.96-6.82-9.21c-0.53-4.6,1.01-8.53,5.13-10.94C65.15,40.85,68.62,41.03,71.77,43.77L71.77,43.77L71.77,43.77z M62.86,52.95c0.05,1.11,0.4,2.16,1.06,3.04c1.36,1.81,3.95,2.19,5.79,0.83c0.3-0.23,0.58-0.5,0.83-0.83 c1.41-1.91,1.41-5.06,0-6.97c-0.71-0.98-1.81-1.56-2.99-1.58C64.77,47.27,62.84,49.4,62.86,52.95L62.86,52.95L62.86,52.95z M89.2,53.1c-0.2-6.46,4.05-11.29,10.09-11.47c6.41-0.2,11.09,4.1,11.29,10.39c0.2,6.36-3.7,10.87-9.71,11.47 C94.3,64.14,89.1,59.39,89.2,53.1L89.2,53.1L89.2,53.1z M95.51,52.5c-0.05,1.26,0.33,2.49,1.08,3.52c1.38,1.81,3.97,2.16,5.79,0.75 c0.28-0.2,0.5-0.45,0.73-0.7c1.46-1.91,1.46-5.13,0.03-7.04c-0.71-0.96-1.81-1.56-2.99-1.58C97.42,47.29,95.51,49.35,95.51,52.5 L95.51,52.5L95.51,52.5z M86.98,48.1c0,3.9,0.03,7.8,0,11.7c0.03,1.79-1.38,3.27-3.17,3.32c-0.3,0-0.63-0.03-0.93-0.1 c-1.26-0.33-2.21-1.66-2.21-3.25v-20c0-1.18-0.03-2.34,0-3.52c0.03-1.94,1.26-3.19,3.12-3.19c1.91-0.02,3.19,1.23,3.19,3.24 C87.01,40.22,86.98,44.17,86.98,48.1L86.98,48.1L86.98,48.1z"/>
              <path class="st1" d="M20.18,0h82.53c5.57,0,10.61,2.26,14.27,5.91c3.65,3.65,5.91,8.7,5.91,14.27v76.22 c0,5.57-2.26,10.62-5.91,14.27c-3.65,3.65-8.7,5.91-14.27,5.91H20.18c-5.57,0-10.61-2.26-14.27-5.91C2.26,107.01,0,101.96,0,96.39 V20.17C0,14.6,2.26,9.56,5.91,5.91C9.56,2.26,14.6,0,20.18,0L20.18,0z M102.71,7.65H20.18c-3.46,0-6.59,1.4-8.86,3.67 c-2.27,2.27-3.67,5.4-3.67,8.86v76.22c0,3.46,1.4,6.59,3.67,8.86c2.27,2.27,5.4,3.67,8.86,3.67h82.53c3.46,0,6.59-1.4,8.86-3.67 c2.27-2.27,3.67-5.4,3.67-8.86V20.17c0-3.46-1.4-6.59-3.67-8.86C109.29,9.05,106.16,7.65,102.71,7.65L102.71,7.65z"/>
            </g>
          </svg>
        </button>
        <button class="btn social-share-btn-icon large-icon" (click)="currentSocialMediaPreviewing = 'line'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'line' && this.socialPlatformVisibility['line'],
                    disable: !this.socialPlatformVisibility['line'] }">
          <svg [ngClass]="{ svg_disable : !this.socialPlatformVisibility['line'] }" class="line-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 122.88" xml:space="preserve">
            <g>
              <title>Line</title>
              <path class="st1" d="M96.26,122.88H26.62C11.92,122.88,0,110.96,0,96.26V26.62C0,11.92,11.92,0,26.62,0h69.63 c14.71,0,26.62,11.92,26.62,26.62v69.63C122.88,110.96,110.96,122.88,96.26,122.88L96.26,122.88z"/>
              <path class="st0" d="M105.98,56.04c0-20.02-20.07-36.31-44.74-36.31c-24.67,0-44.74,16.29-44.74,36.31 c0,17.95,15.92,32.98,37.42,35.82c1.46,0.31,3.44,0.96,3.94,2.21c0.45,1.13,0.29,2.9,0.14,4.04c0,0-0.52,3.16-0.64,3.83 c-0.2,1.13-0.9,4.42,3.88,2.41c4.77-2.01,25.77-15.17,35.15-25.97h0C102.87,71.26,105.98,64.05,105.98,56.04L105.98,56.04z"/>
              <path class="st1" d="M52.15,46.36h-3.14c-0.48,0-0.87,0.39-0.87,0.87v19.49c0,0.48,0.39,0.87,0.87,0.87h3.14 c0.48,0,0.87-0.39,0.87-0.87V47.23C53.02,46.75,52.63,46.36,52.15,46.36L52.15,46.36z"/>
              <path class="st1" d="M73.75,46.36h-3.14c-0.48,0-0.87,0.39-0.87,0.87v11.58l-8.93-12.06c-0.02-0.03-0.04-0.06-0.07-0.09 c0,0,0,0,0-0.01c-0.02-0.02-0.04-0.04-0.05-0.05c-0.01,0-0.01-0.01-0.02-0.02c-0.02-0.01-0.03-0.03-0.05-0.04 c-0.01-0.01-0.01-0.01-0.02-0.02c-0.01-0.01-0.03-0.02-0.04-0.03c-0.01-0.01-0.02-0.01-0.03-0.02c-0.02-0.01-0.03-0.02-0.05-0.03 c-0.01,0-0.02-0.01-0.03-0.01c-0.02-0.01-0.03-0.02-0.05-0.02c-0.01,0-0.02-0.01-0.03-0.01c-0.02-0.01-0.03-0.01-0.05-0.02 c-0.01,0-0.02,0-0.03-0.01c-0.02,0-0.03-0.01-0.05-0.01c-0.01,0-0.02,0-0.04-0.01c-0.02,0-0.03,0-0.05-0.01c-0.02,0-0.03,0-0.04,0 c-0.01,0-0.02,0-0.03,0h-3.14c-0.48,0-0.87,0.39-0.87,0.87v19.49c0,0.48,0.39,0.87,0.87,0.87h3.14c0.48,0,0.87-0.39,0.87-0.87 V55.15l8.94,12.08c0.06,0.09,0.14,0.16,0.22,0.21c0,0,0.01,0,0.01,0.01c0.02,0.01,0.04,0.02,0.05,0.03c0.01,0,0.02,0.01,0.03,0.01 c0.01,0.01,0.03,0.01,0.04,0.02s0.03,0.01,0.04,0.02c0.01,0,0.02,0.01,0.03,0.01c0.02,0.01,0.04,0.01,0.06,0.02c0,0,0.01,0,0.01,0 c0.07,0.02,0.15,0.03,0.22,0.03h3.14c0.48,0,0.87-0.39,0.87-0.87V47.23C74.62,46.75,74.23,46.36,73.75,46.36L73.75,46.36z"/>
              <path class="st1" d="M44.59,62.71h-8.53V47.23c0-0.48-0.39-0.87-0.87-0.87h-3.14c-0.48,0-0.87,0.39-0.87,0.87v19.49 c0,0.23,0.09,0.45,0.24,0.6c0,0,0.01,0.01,0.01,0.01c0,0,0.01,0.01,0.01,0.01c0.16,0.15,0.37,0.24,0.6,0.24h12.54 c0.48,0,0.87-0.39,0.87-0.87v-3.14C45.46,63.11,45.07,62.71,44.59,62.71L44.59,62.71z"/>
              <path class="st1" d="M91.08,51.24c0.48,0,0.87-0.39,0.87-0.87v-3.14c0-0.48-0.39-0.87-0.87-0.87H78.54c-0.23,0-0.45,0.09-0.6,0.25 c0,0-0.01,0.01-0.01,0.01c0,0.01-0.01,0.01-0.01,0.02c-0.15,0.16-0.24,0.37-0.24,0.6v19.49c0,0.23,0.09,0.45,0.24,0.6 c0,0,0.01,0.01,0.01,0.01c0,0,0.01,0.01,0.01,0.01c0.16,0.15,0.37,0.25,0.6,0.25h12.54c0.48,0,0.87-0.39,0.87-0.87v-3.14 c0-0.48-0.39-0.87-0.87-0.87h-8.53v-3.29h8.53c0.48,0,0.87-0.39,0.87-0.87v-3.14c0-0.48-0.39-0.87-0.87-0.87h-8.53v-3.29H91.08 L91.08,51.24z"/>
            </g>
          </svg>
        </button>
        <button class="btn social-share-btn-icon large-icon" (click)="currentSocialMediaPreviewing = 'wechat'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'wechat' && this.socialPlatformVisibility['wechat'],
                    disable: !this.socialPlatformVisibility['wechat'] }">
          <svg class="wechat-icon" [ngClass]="{ svg_disable : !this.socialPlatformVisibility['wechat'] }" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24.5 22.88" xml:space="preserve">
            <g>
              <title>WeChat</title>
              <path class="st1" d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z"/>
              <path class="st0" d="M21.502 19.525c1.524-1.105 2.498-2.738 2.498-4.554 0-3.326-3.237-6.023-7.229-6.023s-7.229 2.697-7.229 6.023c0 3.327 3.237 6.024 7.229 6.024.825 0 1.621-.117 2.36-.33l.212-.032c.139 0 .265.043.384.111l1.583.914.139.045c.133 0 .241-.108.241-.241l-.039-.176-.326-1.215-.025-.154c0-.162.08-.305.202-.392zm-12.827-17.228c-4.791 0-8.675 3.236-8.675 7.229 0 2.178 1.168 4.139 2.997 5.464.147.104.243.276.243.471l-.03.184-.391 1.458-.047.211c0 .16.13.29.289.29l.168-.054 1.899-1.097c.142-.082.293-.133.46-.133l.255.038c.886.255 1.842.397 2.832.397l.476-.012c-.188-.564-.291-1.158-.291-1.771 0-3.641 3.542-6.593 7.911-6.593l.471.012c-.653-3.453-4.24-6.094-8.567-6.094zm5.686 11.711c-.532 0-.963-.432-.963-.964 0-.533.431-.964.963-.964.533 0 .964.431.964.964 0 .532-.431.964-.964.964zm4.82 0c-.533 0-.964-.432-.964-.964 0-.533.431-.964.964-.964.532 0 .963.431.963.964 0 .532-.431.964-.963.964zm-13.398-5.639c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156zm5.783 0c-.639 0-1.156-.518-1.156-1.156 0-.639.517-1.157 1.156-1.157.639 0 1.157.518 1.157 1.157 0 .638-.518 1.156-1.157 1.156z"/>
            </g>
          </svg>
        </button>
        <button class="btn social-share-btn-icon large-icon" (click)="currentSocialMediaPreviewing = 'bluesky'"
                [ngClass]="{active: currentSocialMediaPreviewing == 'bluesky' && this.socialPlatformVisibility['bluesky'],
                    disable: !this.socialPlatformVisibility['bluesky'] }">
          <svg class="bluesky-icon" [ngClass]="{ svg_disable : !this.socialPlatformVisibility['bluesky'] }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>Bluesky</title>
            <path d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/>
          </svg>
        </button>
      </div>
      <div class="col-md-3">
        <div class="text-right">
          <button [disabled]="loader$ | async"
                  class="btn btn-default mr-2" (click)="previewSocialShare()"
                  type="button">
            <span><i class="fa fa-spinner fa-spin mr-2"
                     *ngIf="loader$ | async"></i>Preview</span>
          </button>
          <button [disabled]="loader$ | async" (click)="onNextButtonClick()" class="btn btn-primary">
            <span><i class="fa fa-spinner fa-spin mr-2" *ngIf="loader$ | async"></i>Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
