import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TemplateGeneratorService} from '@app/services/template-generator.service';
import UploadAdapter from '../adapters/upload-adapter';
import * as InlineEditor from '../../../../assets/js/ckeditor.js';
import * as routes from '@app/routes';
import {LANDING_PAGE_TEMPLATE_TYPE_ID, VARIABLES} from '@app/consts';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {FileUpload, getFile, getFileError, getFileSuccess, IFileUploadState, ResetFileState} from '@app/stores';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss', '../../../../assets/css/editor/custom.scss']
})
export class EditTemplateComponent implements OnInit, OnDestroy {
  public campaign;
  public influencer_type_id;
  public template_type_id = LANDING_PAGE_TEMPLATE_TYPE_ID;
  public template;
  public blocks = [];
  public editable_items: HTMLCollection;
  public selected_item;
  public color;
  public FontColor;
  public buttonLink = new UntypedFormControl('');
  public buttonLinkType = new UntypedFormControl('');
  public uploaded_image;
  public uploaded_image_name;
  public is_uploaded_image;
  public editors;
  public page_size_template = [
    {
      icon: 'fa-television',
      name: 'desktop',
      is_active: false,
      page_class: 'col-12 full-page'
    },
    {
      icon: 'fa-tablet',
      name: 'tablet',
      is_active: false,
      page_class: 'col-7 tablet'
    },
    {
      icon: 'fa-mobile',
      name: 'mobile',
      is_active: false,
      page_class: 'col-3 mobile'
    }
  ];
  public selected_page_size;
  private templateSubscription;
  unsubscriber = new Subject();
  image: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private templateGeneratorService: TemplateGeneratorService,
    private toastr: ToastrService,
    private fileUploadStore: Store<IFileUploadState>,
  ) {
    this.templateGeneratorService.onTemplateChanged[this.template_type_id]
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((template) => {
      this.back();
    });
    this.fileUploadStore.dispatch(ResetFileState({ params: { error: '', file: null } }));
    this.fileStoreSubscribe();
  }

  private snoballVariablesToolbar(editorIndex) {
    const {event: {variables}} = this.campaign;
    const customVariables = variables
      .filter(e => e.influencer_type_id === this.influencer_type_id)
      .reduce((group, {name}) => {
        group[name] = {text: `#${name}#`};
        return group;
      }, {});

    return '<select id="editor-insert-variables" onchange="insertVariable(this)">' +
      '<option value="">Insert Variable</option>' +
      Object.entries({...VARIABLES[this.influencer_type_id], ...customVariables}).map(pair => (
        `<option value="${escape(JSON.stringify(pair[1]))}" data-index=${editorIndex}>${pair[0]}</option>`
      )).join(' ') +
      '</select>';
  }


  ngOnInit() {
    this.templateGeneratorService.subscribeStore();
    this.selectPageSize(this.page_size_template[0]);
    this.subscribeToCampaign();
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getFileError)).pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFile)).pipe(takeUntil(this.unsubscriber))
      .subscribe((file: any) => {
        if (file) {
          const {origin, pathname} = new URL(file.url);

          this.uploaded_image_name = origin + pathname;
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(this.image);
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          }).then(data => {
            this.uploaded_image = data;
            this.image = null;
          });
        }
      });
  }

  subscribeToCampaign() {
    this.templateGeneratorService.onCampaignLoaded
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(camp => {
      this.campaign = camp;
      if (!camp) {
        return;
      }
      if (this.campaign.segments.length > 0) {
        this.influencer_type_id = this.campaign.segments[0].influencer_type;
        this.templateGeneratorService.loadClientTemplate(this.template_type_id);
      } else {
        this.router.navigate(
          ['/project/' + this.campaign.project_id + '/add-influencer'],
          {queryParams: {campId: this.campaign.id}}
        );
      }
    });
  }

  appendStylesToHeader() {
    (document.querySelector('.app-alerts') as HTMLElement).style.top = '150px';
  }

  onButtonLinkTypeChange(e: any) {
    const {value} = e.target;

    value === 'custom' ? this.buttonLink.enable() : this.buttonLink.disable();
    value === 'custom' ? this.buttonLink.setValue('') : this.buttonLink.setValue(value);
  }

  editorConfig() {
    if (this.editors) {
      for (let i = 0; i < this.editors.length; i++) {
        this.editors[i].destroy(); /* .then( () => { }); */
      }
    }
    this.editors = [];
    for (let i = 0; i < this.editable_items.length; i++) {
      /*
      InlineEditor
      */
      InlineEditor
        .create(document.getElementById('editable-' + i),
          {
            /*
            fontFamily: {
              options: [
                  // ...
              ],
              supportAllValues: true
            },
              fontColor: {
              colors: [
                  {
                      color: 'hsl(0, 0%, 0%)',
                      label: 'Black'
                  }
              ],
            },
            */
            toolbar: [
              'heading', '|', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', // 'fontFamily',
              'alignment', 'link', 'bulletedList', 'numberedList', '|',
              'imageUpload', 'mediaEmbed', 'insertTable', 'blockQuote', 'undo', 'redo'
            ],
            image: {
              toolbar: [
                'imageResize',
                'linkImage'
              ],
              resizeOptions: [
                {
                  name: 'imageResize:original',
                  label: 'Original',
                  value: null
                },
                {
                  name: 'imageResize:50',
                  label: '50%',
                  value: '50'
                },
                {
                  name: 'imageResize:75',
                  label: '75%',
                  value: '75'
                }
              ],
              styles: [
                'alignLeft', 'alignCenter', 'alignRight'
              ],
              upload: {
                panel: {
                  items: ['insertImageViaUrl']
                }
              }
            },
            /*
            toolbar: {
              items: ['heading', 'bold', 'italic', 'bulletedList', 'numberedList', 'fontFamily']
            },
            */
            extraPlugins: [MyUploadAdapterPlugin]
          })
        .then(editor => {
          this.customToolbarForEditor(editor, i);
          this.editors.push(editor);
        })
        .catch(error => {
          console.error(error);
        });
    }
    window['editors'] = this.editors;

    setTimeout(() => {
      const buttons = document.getElementsByClassName('btn-editable');

      for (let i = 0; i <= buttons.length; i++) {
        if (buttons[i] !== undefined) {
          const typ = document.createAttribute('id');

          typ.value = 'action-button-' + i;
          buttons[i].attributes.setNamedItem(typ);
          buttons[i].addEventListener('mouseenter', (event: object) => {
            const hoverDiv = document.createElement('div');
            const href = buttons[i].getAttribute('href');

            hoverDiv.innerHTML = '<div id="btn-hover-' + i + '" (mouseenter)="showOptions(true, item)"' +
              '                         (mouseleave)="showOptions(false, item)"' +
              '                         style="position: absolute; top: 0; z-index: 1"' +
              '                         class="example-box">' +
              '                        <div class="options text-right" onClick="editButton(event, ' + i + ')">' +
              '                            <button style="position: absolute;right: -23px;"' +
              '                                    class="btn btn-secondary" data-toggle="modal" data-target="#buttonChanger">' +
              '                                <i class="fa fa-pencil"></i>' +
              '                            </button>' +
              '                        </div>' +
              '                        <div class="w-100">' +
              '                            <div [innerHTML]="item[\'body\'] | sanitizeHtml:\'html\'"></div>' +
              '                        </div>' +
              '                    </div>';
            event['target'].appendChild(hoverDiv);

            this.updateButtonSettingsModal(href);
          });
          buttons[i].addEventListener('mouseleave', function (event) {
            document.getElementById('btn-hover-' + i).remove();
          }, false);
        }
      }
    }, 1);
  }


  customToolbarForEditor(editor, editorIndex) {
    const toolbar_element = editor.ui.view.toolbar.element;

    const separator1 = document.createElement('span');
    separator1.innerHTML = '<span class="ck ck-toolbar__separator"></span>';
    toolbar_element.children[0].appendChild(separator1.children[0]);

    // let fonts = document.createElement('div');
    // fonts.innerHTML = EditTemplateComponent.addFontsToolbar();
    // toolbar_element.children[ 0 ].appendChild(fonts.children[ 0 ]);
    //
    // let separator2 = document.createElement('span');
    // separator2.innerHTML = '<span class="ck ck-toolbar__separator"></span>';
    // toolbar_element.children[ 0 ].appendChild(separator2.children[ 0 ]);

    const snoball_variables = document.createElement('div');
    snoball_variables.innerHTML = this.snoballVariablesToolbar(editorIndex);
    toolbar_element.children[0].appendChild(snoball_variables.children[0]);


    // let snoball_buttons = document.createElement('div');
    // snoball_buttons.innerHTML = EditTemplateComponent.insertElementToolbar();
    // toolbar_element.children[0].appendChild(snoball_buttons.children[0]);

    // console.log(toolbar_element);
  }

  showOptions(is_show, item) {
    if (is_show) {
      for (let i = 0; i < this.blocks.length; i++) {
        this.blocks[i]['operation_show'] = false;
      }
      item['operation_show'] = false;
      for (let i = 0; i < this.blocks.length; i++) {
        if (this.blocks[i] === item) {
          this.selected_item = item;
          this.blocks[i]['operation_show'] = true;
        }
      }
    } else {
      for (let i = 0; i < this.blocks.length; i++) {
        this.blocks[i]['operation_show'] = false;
      }
    }
  }

  generateTemplateBody() {
    for (let i = 0; i < this.blocks.length; i++) {
      const div = document.createElement('div');
      div.innerHTML = this.blocks[i]['body'];
      const editable_items = div.children[0].getElementsByClassName('editable');
      if (editable_items.length) {
        for (let j = 0; j < editable_items.length; j++) {
          const id = editable_items[j].getAttribute('id').split('-')[1];
          for (let k = 0; k < this.editors.length; k++) {
            if (k === +id) {
              editable_items[j].innerHTML = this.editors[k].getData();
            }
          }
        }
      }
      this.blocks[i]['body'] = div.innerHTML;

    }

    const temp = document.createElement('div');
    temp.innerHTML = this.template['body'];
    const tempChild = temp.children[0] as HTMLElement;
    const mw = tempChild.style['max-width'];
    const mt = tempChild.style['margin-top'];
    let body = `<div style="max-width: ${mw}; marin-top: ${mt}">`;
    body += '';
    for (let i = 0; i < this.blocks.length; i++) {
      body += this.blocks[i]['body'];
    }
    body += '';
    body += '</div>';
    return body;
  }

  generateTemplateStyle() {
    let style = '';
    for (let i = 0; i < this.blocks.length; i++) {
      style += this.blocks[i]['style'];
    }
    return style;
  }

  back() {
    const body = this.generateTemplateBody();
    const style = this.generateTemplateStyle();

    for (let i = 0; i < this.editors.length; i++) {
      this.editors[i].destroy(); /* .then( () => { }); */
    }

    this.templateSubscription.unsubscribe();
    this.templateGeneratorService.updateTemplateInLocalState({
      ...this.template,
      templateTypeId: this.template_type_id,
      body,
      style
    });
    this.router.navigateByUrl('/' + routes.campaignTemplate({
      projectId: this.campaign.project_id,
      campaignId: this.campaign.id
    }));
  }

  save() {
    const body = this.generateTemplateBody();
    const style = this.generateTemplateStyle();
    this.templateGeneratorService.saveClientTemplate({
      id: null,
      templateTypeId: this.template_type_id,
      campaignId: this.campaign.id,
      clientTemplate: {body, style},
      visualEditorId: null,
    });
  }

  preview() {
    this.templateGeneratorService.preview(this.template_type_id);
  }

  sendTestEmail() {
    const email = prompt('Please enter email to send the test email to.', 'email');
    if (email) {
      this.templateGeneratorService.sendTestEmail(email);
    }
  }

  openModal(template, item) {
    this.color = '';
    this.uploaded_image = '';
    this.selected_item = item;
    this.is_uploaded_image = false;
    this.modalService.open(template, {
      windowClass: 'optional-modal',
      backdropClass: 'optional-modal-backdrop',
      centered: true
    });
  }

  customizeItem(item: any) {
    if (this.uploaded_image) {
      for (let i = 0; i < this.blocks.length; i++) {
        if (this.blocks[i]['body'] === item['body']) {
          const temp = document.createElement('div');
          temp.innerHTML = item['body'];
          temp.children[0].setAttribute('style', 'background-image: url("' + this.uploaded_image_name + '");background-size: cover;background-repeat: no-repeat;background-position: center;');
          const x = {
            body: temp.innerHTML,
            operation_show: false
          };
          this.blocks.splice(i, 1);
          this.blocks.splice(i, 0, x);
        }
      }
    }
    if (this.color) {
      for (let i = 0; i < this.blocks.length; i++) {
        if (this.blocks[i]['body'] === item['body']) {
          const temp = document.createElement('div');
          temp.innerHTML = item['body'];
          temp.children[0].setAttribute('style', 'background: ' + this.color + ';');
          const x = {
            body: temp.innerHTML,
            operation_show: false
          };
          this.blocks.splice(i, 1);
          this.blocks.splice(i, 0, x);
        }
      }
    }
    this.initialTemplateAfterChangeBackground();
  }

  resetButtonSettingsModal() {
    this.updateButtonSettingsModal('');
  }

  updateButtonSettingsModal(value) {
    const isCustomValue = value !== '#RegistrationLink#';

    this.buttonLink.setValue(value);
    this.buttonLinkType.setValue(isCustomValue ? 'custom' : value);
    isCustomValue ? this.buttonLink.enable() : this.buttonLink.disable();
  }

  saveButtonStyle(backgroundColor, fontColor, buttonLink) {
    const closeButton = document.getElementById('buttonChangerClose');
    const div = document.createElement('div');

    div.innerHTML = this.selected_item['body'];
    const editable_btn = div.getElementsByClassName('btn-editable').item(0);

    if (backgroundColor) {
      editable_btn['style'].background = backgroundColor;
    }
    if (fontColor) {
      editable_btn['style'].color = fontColor;
    }
    if (buttonLink !== '') {
      editable_btn.setAttribute('href', buttonLink);
    }

    const index = this.blocks.findIndex(section => section === this.selected_item);

    if (index !== -1) {
      this.blocks[index]['body'] = div.innerHTML;
      this.editorConfig();
    }

    this.resetButtonSettingsModal();
    closeButton.click();
  }

  selectBackgroundImage($event) {
    const image = $event.target.files[0];
    this.is_uploaded_image = true;
    this.uploadImage(image);
  }

  async uploadImage(image) {
    if (image) {
      this.image = image;
      // this.fileUploadStore.dispatch(FileUpload({file: image}));
    }
  }

  selectPageSize(item) {
    for (let i = 0; i < this.page_size_template.length; i++) {
      this.page_size_template[i].is_active = false;
    }
    this.selected_page_size = item;
    this.selected_page_size.is_active = true;
  }

  private initialTemplateAfterChangeBackground() {
    for (let i = 0; i < this.editors.length; i++) {
      this.editors[i].destroy(); /* .then( () => { }); */
    }
    setTimeout(() => {
      this.editorConfig();
    }, 1);
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
    this.templateGeneratorService.unsubscribe();
  }
}

function MyUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new UploadAdapter(loader);
  };
}
