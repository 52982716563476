<div class="modal-header">
    <h4
        class="modal-title"
        id="modal-title"
    >
      {{this.launchType === 'relaunch' ? 'Schedule ReLaunch' : 'Schedule Launch'}}
    </h4>
    <div class="d-flex justify-content-end">
        <button
            type="button"
            aria-label="Close button"
            class="close"
            aria-describedby="modal-title"
            (click)="modal.close()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="modal-body">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-12">
                <div>Choose the date and time you want the campaign to be launched</div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="calendar">
                            <div class="form-group datePicker">
                                <input
                                    [minDate]="min"
                                    bsDatepicker
                                    class="form-control"
                                    formControlName="schedule_date"
                                    placeholder="Date"
                                    type="text"
                                />
                                <span class="date-ic">
                                    <i class="fa fa-calendar"><span class="red">*</span></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="Time">
                            <div class="toggle-example custom-timePicker">
                                <input
                                    [ngxTimepicker]="toggleTimepicker"
                                    formControlName="schedule_time"
                                    placeholder="Time"
                                    readonly
                                />
                                <ngx-material-timepicker-toggle [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
                                <ngx-material-timepicker
                                    timepickerClass="testing"
                                    #toggleTimepicker
                                ></ngx-material-timepicker>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12" style="margin-top: 10px">
                        <div class="floating-label">
                            <i
                                aria-hidden="true"
                                class="fa fa-angle-down chevronSelectOptionIcon"
                            ></i>
                          <app-timezone-picker class="dropdown-panel-timezone" [setDefaultValue]="form.get('timezone').value" (formControlValue)="getTimezoneValue($event)" [customPlaceholderText]="'Time Zone'" [isClearable]='true'></app-timezone-picker>
<!--                          <ng-moment-timezone-picker class="dropdown-panel-timezone" [formControlName]="'timezone'" [customPlaceholderText]='"Time Zone"' [clearable]='true'></ng-moment-timezone-picker>-->
                            <span class="highlight"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<input
    type="hidden"
    ngbAutofocus
>
<div class="modal-footer">
    <button
        class="btn"
        type="button"
        (click)="close()"
    >
        Cancel
    </button>
    <button
        class="btn btn-primary"
        type="button"
        (click)="save()"
    >
        Save
    </button>
</div>
